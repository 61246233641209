import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Typography,
} from 'antd';
import ButtonToForm from '../../components/ButtonToForm';
import { useCallback } from 'react';
import { FVER } from '../../../sdk/@types';
import { FVERFormulariosAnexos } from '../../../sdk/@types/FVERFormulariosAnexos';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

interface FormulariosAnexosFVERProps {
  formulariosAnexos?: FVERFormulariosAnexos.Summary;
  fver: FVER.Summary;
  local: boolean;
  statusLocal?: 'ERRO' | 'NAO FINALIZADO' | 'NOVO' | 'SINCRONIZADO';
}

export default function FormulariosAnexosFVER(
  props: FormulariosAnexosFVERProps
) {
  const navigate = useNavigate();

  const fverNaoPossuiNenhumAnexo = useCallback(() => {
    return props.formulariosAnexos === undefined;
  }, [props]);

  const linkNewFVV = `/vigilancias/cadastro/${props.fver.id}${
    props.local ? '/local' : ''
  }`;
  const linkEditFVV = `/vigilancias/edicao/${props.formulariosAnexos!.fvv?.id}${
    props.local ? '/local' : ''
  }`;
  const linkPrintViewFVV = `/vigilancias/visualizarP/${
    props.formulariosAnexos!.fvv?.id
  }${props.local ? '/local' : ''}`;
  const linkNewLIA = `/lia/cadastro/${props.fver.id}${
    props.local ? '/local' : ''
  }`;
  const linkEditLIA = `/lia/edicao/${props.formulariosAnexos!.lia?.id}${
    props.local ? '/local' : ''
  }`;

  interface DescriptionItemProps {
    id: number;
    label: string;
    route: string;
  }
  const formularioAnexo: React.FC<DescriptionItemProps> = (props) => {
    return (
      <Row
        gutter={24}
        justify='space-between'
        style={{ width: '100%' }}
        align='middle'
      >
        <Col span={24}>
          <Typography.Text>
            <b>{props.label}</b>
          </Typography.Text>
        </Col>
        <Col span={20}>{props.id}</Col>
        <Col span={4}>
          <Button
            icon={<SearchOutlined />}
            onClick={() => navigate(props.route)}
            type='primary'
          />
        </Col>

        <Divider style={{ padding: '0', margin: '0', marginTop: '4px' }} />
      </Row>
    );
  };

  return (
    <>
      {props.formulariosAnexos?.fvv?.id &&
        formularioAnexo({
          id: props.formulariosAnexos?.fvv?.id,
          label: 'FVV',
          route: linkPrintViewFVV,
        })}

      {props.formulariosAnexos?.lia?.id &&
        formularioAnexo({
          id: props.formulariosAnexos?.lia?.id,
          label: 'Laudo de Inspeção',
          route: linkEditLIA,
        })}
    </>
  );
}
