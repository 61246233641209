import { configureStore } from '@reduxjs/toolkit';
import AssinaturaBase64ImageReducer from './AssinaturaBase64Image.slice';
import LoadingPageReducer from './LoadingPage.slice';
import ServiceWorkerControllerReducer from './ServiceWorkerController.slice';
import SyncingStateReducer from './SyncingState.slice';
import FVERReducer from './FVER.slice';
import BadgeStateReducer from './BadgeState.slice';
import OfflinePersistStateReducer from './OfflinePersistState.slice';
import FVVReducer from './FVV.slice';
import TipoChavePrincipalTFRReducer from './TipoChavePrincipalTFR.slice';
import RevendasReducer from './Revenda.slice';
import TermoFiscalizacaoReducer from './TFR.slice';
import EstabelecimentoAvicolaReducer from './EstabelecimentoAvicola.slice';
import EmpresaIntegradoraReducer from './EmpresaIntegradora.slice';
import DevOptionsReducer from './DevOptions.slice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import PermissoesReducer from './Permissoes.slice';
import LiaReducer from './LIA.slice';

import UsuarioReducer from './Usuario.slice';
import LatestVersionAvaiableStateReducer from './LatestVersionAvaiableState.slice';
import HistoricoEstabelecimentoReducer from './HistoricoEstabelecimento.slice';

require('dotenv').config();

const persistConfig = {
  key: 'root',
  storage: storage,
};

const permissoesReducer = PermissoesReducer;
const latestVersionAvaiableStateReducer = LatestVersionAvaiableStateReducer;

const PersistedPermissoesReducer = persistReducer(
  persistConfig,
  permissoesReducer
);

const PersistedLatestVersionAvaiableStateReducer = persistReducer(
  persistConfig,
  latestVersionAvaiableStateReducer
);

export const store = configureStore({
  reducer: {
    assinaturaBase64Image: AssinaturaBase64ImageReducer,
    badgeState: BadgeStateReducer,
    estabelecimentoAvicola: EstabelecimentoAvicolaReducer,
    empresaIntegradora: EmpresaIntegradoraReducer,
    formTfr: TermoFiscalizacaoReducer,
    fver: FVERReducer,
    fvv: FVVReducer,
    devOptions: DevOptionsReducer,
    historicoEstabelecimento: HistoricoEstabelecimentoReducer,
    latestVersionAvaiableState: PersistedLatestVersionAvaiableStateReducer,
    lia: LiaReducer,
    loadingPage: LoadingPageReducer,
    offlinePersistState: OfflinePersistStateReducer,
    permissoesReducer: PersistedPermissoesReducer,
    revenda: RevendasReducer,
    serviceWorkerController: ServiceWorkerControllerReducer,
    syncing: SyncingStateReducer,
    tfr: TipoChavePrincipalTFRReducer,
    usuario: UsuarioReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
