import { SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { store } from '../../../core/store';
import { setDataBaseStatus } from '../../../core/store/LoadingPage.slice';
import {
  InitializerStatus,
  persistOfflineGlobalData,
  persistServidor,
  persistTipoChavePrincipal,
  persistTipoChaveSecundaria,
  persistVeterinario,
  persistTipoChavePrincipalTFR,
  persistEmpresaIntegradora,
} from '../../../sdk/services/indexeddb/Initializer';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import TipoChavePrincipalFVERIDBService from '../../../sdk/services/indexeddb/TipoChavePrincipalFVERIDB.service';
import TipoChaveSecundariaFVERIDBService from '../../../sdk/services/indexeddb/TipoChaveSecundariaFVERIDB.service';
import VeterinarioIDBService from '../../../sdk/services/indexeddb/VeterinarioIDB.service';
import TipoChavePrincipalTFRIDBService from '../../../sdk/services/indexeddb/TipoChavePrincipalTFRIDB.service';
import EmpresaIntegradoraIDBService from '../../../sdk/services/indexeddb/EmpresaIntegradoraIDB.service';
import ServiceIDB from '../../../sdk/services/indexeddb/ServiceIDB';

export default function GlobalDataOffline() {
  const { online } = useNavigatorStatus();
  const { setLoading } = useLoadingPage();
  const [sincronizando, setSincronizando] = useState<boolean>(false);

  const [dadosSincronizados, setDadosSincronizados] = useState<
    {
      type:
        | 'SERVIDOR'
        | 'TIPO_CHAVE_PRINCIPAL_FVER'
        | 'TIPO_CHAVE_SECUNDARIA_FVER'
        | 'VETERINARIO'
        | 'TIPO_CHAVE_PRINCIPAL_TFR'
        | 'EMPRESA_INDEPENDENTE';
      name: string;
      date: Date;
    }[]
  >([]);

  const [error, setError] = useState<Error>();

  const populate = async (forceUpdate?: boolean) => {
    let helper: any[] = [];
    try {
      await ServidorIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'SERVIDOR',
          name: 'Servidor',
          date: item ? item.date : undefined,
        });
      });
      await TipoChavePrincipalFVERIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'TIPO_CHAVE_PRINCIPAL_FVER',
          name: 'Tipo Chave Principal',
          date: item ? item.date : undefined,
        });
      });
      await TipoChaveSecundariaFVERIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'TIPO_CHAVE_SECUNDARIA_FVER',
          name: 'Tipo Chave Secundária',
          date: item ? item.date : undefined,
        });
      });
      await VeterinarioIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'VETERINARIO',
          name: 'Veterinários',
          date: item ? item.date : undefined,
        });
      });
      await TipoChavePrincipalTFRIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'TIPO_CHAVE_PRINCIPAL_TFR',
          name: 'Tipo Chave PrincipalTFR',
          date: item ? item.date : undefined,
        });
      });
      await EmpresaIntegradoraIDBService.getFirst().then((item) => {
        helper = helper.concat({
          type: 'EMPRESA_INDEPENDENTE',
          name: 'Empresa Integradora',
          date: item ? item.date : undefined,
        });
      });
    } catch (e) {
      setError(
        new Error(
          'Erro ao buscar dados offline. Contate o administrador do sistema.'
        )
      );
    }

    setDadosSincronizados([]);
    setDadosSincronizados(helper);
  };

  useEffect(() => {
    if (!sincronizando) populate(true);
  }, [sincronizando]);

  useEffect(() => {
    populate();
  }, []);

  const ressincronizar = useCallback(
    async (
      type:
        | 'SERVIDOR'
        | 'TIPO_CHAVE_PRINCIPAL_FVER'
        | 'TIPO_CHAVE_SECUNDARIA_FVER'
        | 'VETERINARIO'
        | 'TIPO_CHAVE_PRINCIPAL_TFR'
        | 'EMPRESA_INDEPENDENTE'
    ) => {
      switch (type) {
        case 'SERVIDOR': {
          return persistServidor(true);
        }
        case 'TIPO_CHAVE_PRINCIPAL_FVER': {
          return persistTipoChavePrincipal(true);
        }
        case 'TIPO_CHAVE_SECUNDARIA_FVER': {
          return persistTipoChaveSecundaria(true);
        }
        case 'VETERINARIO': {
          return persistVeterinario(true);
        }
        case 'TIPO_CHAVE_PRINCIPAL_TFR': {
          return persistTipoChavePrincipalTFR(true);
        }
        case 'EMPRESA_INDEPENDENTE': {
          return persistEmpresaIntegradora(true);
        }
      }
    },
    []
  );

  if (error) throw error;

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Table
            dataSource={dadosSincronizados}
            rowKey={'type'}
            size={'small'}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Dados globais
                  </Typography.Title>

                  {
                    <Col>
                      <Space>
                        <Button
                          icon={<SyncOutlined />}
                          type={'primary'}
                          title={`${
                            !online
                              ? 'Sem conexão com a internet. Não é possível ressincronizar'
                              : error !== undefined
                              ? 'Erro ao tentar se conectar com o servidor. Não é possível sincronizar'
                              : 'Ressincronizar'
                          }`}
                          disabled={!online || error !== undefined}
                          onClick={async () => {
                            setLoading(true);
                            setSincronizando(true);
                            await persistOfflineGlobalData(true)
                              .then((result) => {
                                const statusSincronizacao: InitializerStatus =
                                  result[0];
                                setLoading(false);
                                if (
                                  statusSincronizacao ===
                                  InitializerStatus.ERROR
                                )
                                  notification.error({
                                    message:
                                      'Houve um erro durante a sincronização de dados globais',
                                  });

                                if (
                                  statusSincronizacao ===
                                  InitializerStatus.UPDATED
                                )
                                  notification.success({
                                    message:
                                      'Sincronização de dados globais realizada com sucesso',
                                  });
                              })
                              .catch((e) => {
                                notification.error({
                                  message:
                                    'Ocorreu um erro na sincronização de dados globais',
                                });

                                setLoading(false);
                              })
                              .finally(() => {
                                store.dispatch(setDataBaseStatus('FINISHED'));
                                setSincronizando(false);
                                setLoading(false);
                              });
                          }}
                        />
                      </Space>
                    </Col>
                  }
                </Row>
              );
            }}
            columns={[
              {
                dataIndex: ['name'],
                title: 'Nome',
                width: '50%',
              },
              {
                dataIndex: 'date',
                title: 'Data de sincronização',
                render(date: string) {
                  return moment(date).format('DD/MM/YYYY');
                },
              },

              {
                dataIndex: 'date',
                title: 'Status',
                render(date: Date) {
                  const expired = ServiceIDB.isDataExpired(date);
                  return (
                    <Tag
                      color={expired === undefined || expired ? 'red' : 'green'}
                    >
                      {expired === undefined
                        ? 'NÃO SINCRONIZADO'
                        : expired
                        ? 'EXPIRADO'
                        : 'VÁLIDO'}
                    </Tag>
                  );
                },
              },

              {
                dataIndex: ['payload', 'id'],
                title: 'Ações',
                width: 100,
                render(id, row) {
                  return (
                    <>
                      <Button
                        icon={<SyncOutlined />}
                        title='Atualizar'
                        size='small'
                        type='ghost'
                        onClick={async () => {
                          setLoading(true);
                          setSincronizando(true);
                          await ressincronizar(row.type)
                            .then(async (result) => {
                              const statusSincronizacao: InitializerStatus =
                                result;
                              setLoading(false);
                              if (
                                statusSincronizacao === InitializerStatus.ERROR
                              )
                                notification.error({
                                  message:
                                    'Houve um erro durante a sincronização de dados globais',
                                });

                              if (
                                statusSincronizacao ===
                                InitializerStatus.UPDATED
                              )
                                notification.success({
                                  message:
                                    'Sincronização realizada com sucesso',
                                });
                            })
                            .catch((e) => {
                              notification.error({
                                message: 'Ocorreu um erro na sincronização',
                              });

                              setLoading(false);
                            })
                            .finally(() => {
                              setSincronizando(false);
                              setLoading(false);
                            });
                        }}
                      />
                    </>
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
