import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Form,
  FormInstance,
  Input,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useState } from 'react';
import scrollFieldToTop from '../../core/functions/scrollFieldToTop';
import useLoadingPage from '../../core/hooks/useLoadingPage';
import useTFRServidor from '../../core/hooks/useTFRServidor';
import { TFRServidor } from '../../sdk/@types';
import { CustomModal } from './CustomModal';

interface ServidoresProps {
  form: FormInstance;
  formDisabled: boolean;
  listaServidores: TFRServidor.Summary[];
  setListaServidores: (listaServidores: TFRServidor.Summary[]) => void;
}

export default function TFRServidoresPanel(props: ServidoresProps) {
  const { xs } = useBreakpoint();
  const { setLoading } = useLoadingPage();
  const [showModalServidores, setShowModalServidores] =
    useState<boolean>(false);
  const [cpf, setCpf] = useState<any>('');
  const [matricula, setMatricula] = useState<any>('');
  const [nome, setNome] = useState<any>('');
  const {
    servidor,
    fetchExistingServidorByCpf,
    fetchExistingServidorByMatricula,
    fetchingServidor,
  } = useTFRServidor();
  const [servidorSelecionado, setServidorSelecionado] =
    useState<boolean>(false);

  useEffect(() => {
    //se o servidor for undefined, quer dizer que é o primeiro uso do useServidor
    if (servidor === undefined) return;

    if (!fetchingServidor) {
      if (!servidor) {
        notification.info({
          message: `Não foi possível encontrar nenhum servidor`,
        });
      } else {
        props.form.setFieldsValue({
          servidores: servidor,
        });

        setServidorSelecionado(true);

        let servidores = props.form.getFieldValue(['servidores']);
        setCpf(servidores.pessoa.cpfCnpj);
        setMatricula(servidores.matricula);
        setNome(servidores.pessoa.nome);
        notification.success({
          message: `Servidor ${servidores?.pessoa?.nome} encontrado`,
        });
      }
    }
  }, [fetchingServidor, setLoading]);

  const cleanModalServidor = useCallback(() => {
    props.form.setFieldsValue({
      servidores: undefined,
    });
    setCpf(null);
    setMatricula(null);
    setNome(null);
    setServidorSelecionado(false);
  }, [props.form]);

  const handleAddServidor = useCallback(() => {
    const servidorSendoInserido = props.form.getFieldValue(['servidores']);

    const servidorJaExiste = props.listaServidores.some(
      (servidor) =>
        servidor.servidor?.matricula === servidorSendoInserido?.matricula
    );

    if (servidorJaExiste) {
      notification.warn({
        message: 'Este servidor já foi incluído',
        description: 'Selecione outro servidor',
      });
      return;
    }
    const novoServidor = [
      { id: servidorSendoInserido.id, servidor: servidorSendoInserido },
    ];

    props.setListaServidores(props.listaServidores.concat(novoServidor));

    setShowModalServidores(false);
    cleanModalServidor();
    props.form.validateFields(['tableServidores']);
    notification.success({
      message: `Servidor ${servidorSendoInserido?.pessoa.nome} incluído com sucesso`,
    });
  }, [props.form, props.listaServidores, cleanModalServidor]);

  const servidorTableActions = (servidor: TFRServidor.Summary['servidor']) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          title={'Deseja remover o servidor?'}
          disabled={props.formDisabled}
          onConfirm={() => {
            props.setListaServidores(
              props.listaServidores.filter(
                (servidorFilter) =>
                  servidorFilter.servidor?.matricula !== servidor?.matricula
              )
            );
            notification.success({
              message: `Servidor ${servidor?.pessoa?.nome} removido`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            disabled={props.formDisabled}
            type={'ghost'}
            title={'Remover servidor'}
          />
        </Popconfirm>
      </Row>
    );
  };

  const fetchServidorByCpf = useCallback(
    async (cpf: string) => {
      props.form.validateFields([['servidores', 'pessoa', 'cpfCnpj']]);
      if (cpf) {
        await fetchExistingServidorByCpf(cpf);
      } else return;
    },
    [fetchExistingServidorByCpf, props.form]
  );

  const fetchServidorByMatricula = useCallback(
    async (matricula: string) => {
      props.form.validateFields([['servidores', 'servidor', 'matricula']]);
      if (matricula) {
        await fetchExistingServidorByMatricula(matricula);
      } else return;
    },
    [fetchExistingServidorByMatricula, props.form]
  );

  return (
    <>
      <Col xs={24} lg={24}>
        <Form.Item
          validateTrigger={'onSubmit'}
          name='tableServidores'
          rules={[
            {
              validator(_, value) {
                if (props.listaServidores.length < 1)
                  return Promise.reject(
                    new Error('Deve ser informado pelo menos um servidor')
                  );
                return Promise.resolve();
              },
            },
          ]}
        >
          <Table<TFRServidor.Summary>
            dataSource={props.listaServidores}
            size={'small'}
            rowKey={(record) => record.servidor?.matricula as string}
            title={() => {
              return (
                <Row justify={'space-between'}>
                  <Typography.Title level={5} style={{ color: 'white' }}>
                    Servidores
                  </Typography.Title>
                  <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() => {
                      setShowModalServidores(true);

                      scrollFieldToTop('tableServidores');
                    }}
                    disabled={props.formDisabled}
                  />
                </Row>
              );
            }}
            columns={[
              {
                dataIndex: 'matricula',
                responsive: ['xs'],
                render(_, servidor) {
                  return (
                    <Descriptions size={'small'} column={1} bordered>
                      <Descriptions.Item label={'Nome'}>
                        {servidor.servidor?.pessoa.nome}
                      </Descriptions.Item>
                      <Descriptions.Item label={'CPF'}>
                        {servidor.servidor?.pessoa.cpfCnpj}
                      </Descriptions.Item>
                      <Descriptions.Item label={'Matrícula'}>
                        {servidor.servidor?.matricula}
                      </Descriptions.Item>
                      <Descriptions.Item label='Ações'>
                        {servidorTableActions(servidor.servidor)}
                      </Descriptions.Item>
                    </Descriptions>
                  );
                },
              },

              {
                dataIndex: ['servidor', 'pessoa', 'nome'],
                title: 'Nome',
                responsive: ['sm'],
                width: 450,
              },
              {
                dataIndex: ['servidor', 'pessoa', 'cpfCnpj'],
                responsive: ['sm'],
                title: 'CPF',
              },
              {
                dataIndex: ['servidor', 'matricula'],
                responsive: ['sm'],
                title: 'Matrícula',
              },
              {
                dataIndex: ['servidor', 'funcao', 'nome'],
                responsive: ['sm'],
                title: 'Funçao',
              },
              {
                dataIndex: 'id',
                title: 'Ações',
                responsive: ['sm'],
                width: 60,
                render(_, servidor) {
                  return servidorTableActions(servidor.servidor);
                },
              },
            ]}
          />
        </Form.Item>
      </Col>

      <CustomModal
        centered
        open={showModalServidores}
        title={'Cadastro de servidores'}
        onCancel={() => {
          setShowModalServidores(false);
          cleanModalServidor();
        }}
        width={800}
        footer={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              style={{ width: '50%' }}
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalServidores(false);
                cleanModalServidor();
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '50%' }}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                props.form.validateFields([
                  ['servidores', 'pessoa', 'cpfCnpj'],
                  ['servidores', 'pessoa', 'nome'],
                  ['servidores', 'matricula'],
                  ['servidores', 'id'],
                ]);

                const cpf = props.form.getFieldValue([
                  'servidores',
                  'pessoa',
                  'cpfCnpj',
                ]);
                const matricula = props.form.getFieldValue([
                  'servidores',
                  'matricula',
                ]);

                const nome = props.form.getFieldValue([
                  'servidores',
                  'pessoa',
                  'nome',
                ]);
                if (!cpf || !matricula || !nome) {
                  notification.warn({
                    message: 'Não foi possível salvar o servidor',
                    description: 'Nenhum servidor selecionado',
                  });
                } else handleAddServidor();
              }}
            >
              Salvar
            </Button>
          </div>
        }
        maskClosable={false}
        destroyOnClose
      >
        <Form layout={'vertical'} size={!xs ? 'small' : 'large'}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'CPF'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input.Search
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  width={'100%'}
                  placeholder={'Informe cpf do servidor'}
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabled={servidorSelecionado}
                  inputMode='numeric'
                  addonAfter={
                    <Button
                      disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={cleanModalServidor}
                    />
                  }
                  onSearch={(cpf) => {
                    if (!cpf) return;
                    const cpfFormatado = cpf.replace(/\D/g, '');
                    if (!cpfFormatado) cleanModalServidor();
                    else fetchServidorByCpf(cpfFormatado);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Matrícula'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input.Search
                  value={matricula}
                  onChange={(e) => setMatricula(e.target.value)}
                  width={'100%'}
                  placeholder={'Informe a matrícula do servidor'}
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabled={servidorSelecionado}
                  inputMode='numeric'
                  addonAfter={
                    <Button
                      disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={cleanModalServidor}
                    />
                  }
                  onSearch={(matricula) => {
                    if (!matricula) cleanModalServidor();
                    else fetchServidorByMatricula(matricula);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Nome'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
              >
                <Input value={nome} disabled />
              </Form.Item>
            </Col>

            <Col xs={0} lg={0}>
              <Form.Item label={''} name={['servidores', 'matricula']} hidden>
                <Input hidden />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  );
}
