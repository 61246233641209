import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import TextArea from 'antd/lib/input/TextArea';
import confirm from 'antd/lib/modal/confirm';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Especie } from '../../../core/enums/Especie';
import { FuncoesNoEstabelecimento } from '../../../core/enums/FuncoesNoEstabelecimento';
import { TipoDeAgrupamento } from '../../../core/enums/TipoDeAgrupamento';
import { TipoVinculoEpidemiologico } from '../../../core/enums/TipoVinculoEpidemiologico';
import validatePhoneInput from '../../../core/functions/validatePhoneInput';
import useFormIN from '../../../core/hooks/useFormIN';
import useFormVIN from '../../../core/hooks/useFormVIN';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import usePropriedade from '../../../core/hooks/usePropriedade';
import {
  FormVIN,
  NovoEstabelecimentoParaInvestigacao,
  Produtor,
  VistoriaFormVIN,
} from '../../../sdk/@types';
import { FormIN } from '../../../sdk/@types/FormIN';
import FormVINIDBService from '../../../sdk/services/indexeddb/FormVINIDB.service';
import AbatedouroPanel from '../../components/AbatedouroPanel';
import PropriedadePanel from '../../components/PropriedadePanel';
import VeterinarioPanel from '../../components/VeterinarioPanel';
import { CustomModal } from '../../components/CustomModal';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';

type FormFormVINType = {
  dataInspecao?: Moment;
  formIN: FormIN.Summary;
  modalVistoria?: VistoriaFormVIN.Input;
  modalNovoEstabelecimentoParaInvestigacao?: NovoEstabelecimentoParaInvestigacao.Input;
} & Omit<FormVIN.Input, 'dataInspecao'>;

type FormFormVINProps = {
  formVIN?: FormFormVINType;
};

export default function FormFormVIN(props: FormFormVINProps) {
  const { online } = useNavigatorStatus();
  const [error, setError] = useState<Error>();

  const [form] = Form.useForm<FormFormVINType>();
  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const { firstOpening, mode, loading, setFirstOpening, setLoading } =
    useLoadingPage();

  const { propriedade, fetchPropriedadesById } = usePropriedade();
  const { formVIN, insertNewFormVIN } = useFormVIN();
  const { formIN, fetchExistingFormINByNumero } = useFormIN();

  const listaEspecies = Especie.keys();
  const listaTipoDeAgrupamento = TipoDeAgrupamento.keys();
  const listaTipoVinculoEpidemiologico = TipoVinculoEpidemiologico.keys();
  const listaFuncoes = FuncoesNoEstabelecimento.keys();

  const [formINSelecionado, setFormINSelecionado] = useState<boolean>(false);
  const [propriedadeSelecionada, setPropriedadeSelecionada] =
    useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchingFormIN, setFetchingFormIN] = useState<boolean>(false);

  const [showAbatedouroPanel, setShowAbatedouroPanel] =
    useState<boolean>(false);
  const [showPropriedadePanel, setShowPropriedadePanel] =
    useState<boolean>(false);
  const [showModalVistorias, setShowModalVistorias] = useState<boolean>(false);
  const [
    showModalNovoEstabelecimentoParaInvestigacao,
    setShowModalNovoEstabelecimentoParaInvestigacao,
  ] = useState<boolean>(false);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  const [editingVistoria, setEditingVistoria] = useState<boolean>(false);
  const [
    editingNovoEstabelecimentoParaInvestigacao,
    setEditingNovoEstabelecimentoParaInvestigacao,
  ] = useState<boolean>(false);

  const [dadosFormVINModalFinalizacao, setDadosFormVINModalFinalizacao] =
    useState<{ id: string; numero: string }>();

  const [dataSourceTableVistorias, setDataSourceTableVistorias] = useState<
    FormFormVINType['vistoriasFormVIN']
  >([]);
  const [
    dataSourceTableNovoEstabelecimentoParaInvestigacao,
    setDataSourceTableNovoEstabelecimentoParaInvestigacao,
  ] = useState<FormFormVINType['novoEstabelecimentoParaInvestigacoes']>([]);

  const handleFormSubmit = useCallback(
    async (formParam: FormFormVINType) => {
      setLoading(true);
      const formVINDTO: FormVIN.Input = {
        ...formParam,
        dataInspecao: JSON.stringify(
          form.getFieldValue('dataInspecao').toDate()
        ).replaceAll('"', ''),
        ausenciaDeAnimaisCompativeisComADoencaInvestigada:
          formParam.ausenciaDeAnimaisCompativeisComADoencaInvestigada
            ? 'SIM'
            : 'NAO',
        vistoriasFormVIN: dataSourceTableVistorias,
        novoEstabelecimentoParaInvestigacoes:
          dataSourceTableNovoEstabelecimentoParaInvestigacao,
      };

      await insertNewFormVIN(formVINDTO)
        .then((formVIN) => {
          //deletar visita se for local
          if (params.local) {
            FormVINIDBService.delete(Number(params.id));
          }

          setDadosFormVINModalFinalizacao({
            //@ts-ignore
            id: formVIN.id,
            //@ts-ignore
            numero: formVIN.numeroInspecao,
          });

          setShowModalFinalizacao(true);
        })
        .catch((e) => {
          setLoading(false);
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      dataSourceTableNovoEstabelecimentoParaInvestigacao,
      dataSourceTableVistorias,
      form,
      insertNewFormVIN,
      params,
      setLoading,
    ]
  );

  const removeFormIN = () => {
    setFormINSelecionado(false);
    form.setFieldsValue({
      formIN: undefined,
    });
  };

  const fetchFormINByNumero = async (numero: string) => {
    if (!numero) return;

    setLoading(true);
    setFetchingFormIN(true);
    try {
      await fetchExistingFormINByNumero(numero)
        .catch((e) => {
          setFetchingFormIN(false);
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } finally {
      setFetchingFormIN(false);
    }
  };

  const fetchPropriedadeByCodigo = useCallback(
    async (id: string) => {
      if (!id) return;
      if (isNaN(Number(id))) return;

      setFetching(true);
      setLoading(true);
      try {
        await fetchPropriedadesById(Number(id), online ? true : false)
          .catch((e) => {
            setLoading(false);
            setFetching(false);
            setError(e);
          })
          .finally(() => {
            setLoading(false);
            setFetching(false);
          });
      } finally {
      }
    },
    [fetchPropriedadesById, setLoading]
  );

  useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      fetchFormINByNumero(form.getFieldValue(['formIN', 'numero']))
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setFormDisabled(mode === 'VIEW' ? true : false);
  }, [mode]);

  useEffect(() => {
    switchPanel(form.getFieldValue('tipoEstabelecimento'));

    if (props.formVIN?.vistoriasFormVIN)
      setDataSourceTableVistorias(props.formVIN.vistoriasFormVIN);
    if (props.formVIN?.novoEstabelecimentoParaInvestigacoes)
      setDataSourceTableNovoEstabelecimentoParaInvestigacao(
        props.formVIN.novoEstabelecimentoParaInvestigacoes
      );
  }, [props.formVIN, form]);

  useEffect(() => {
    if (!form.getFieldValue(['formIN', 'numero'])) return;
    if (!online && mode === 'EDIT') return;
    if (mode === 'VIEW') return;

    if (!fetchingFormIN) {
      if (!formIN) {
        if (firstOpening) {
          notification.info({
            message: `Não foi possível encontrar nenhum Form IN`,
          });
        }
      } else {
        form.setFieldsValue({
          formIN: {
            ...formIN,
          },
        });

        setFormINSelecionado(true);
        notification.success({
          message: `FormIN ${formIN.numero} selecionado`,
        });
        setFirstOpening(false);
      }
    }
    setLoading(fetchingFormIN);
  }, [fetchingFormIN]);

  useEffect(() => {
    if (!showModalNovoEstabelecimentoParaInvestigacao) return;

    if (propriedade) {
      form.setFieldsValue({
        modalNovoEstabelecimentoParaInvestigacao: {
          ...form.getFieldsValue(['modalNovoEstabelecimentoParaInvestigacao']),
          propriedade: propriedade,
        },
      });
      setPropriedadeSelecionada(true);
      notification.success({
        message: `Propriedade ${propriedade.nome} encontrada`,
      });
    } else {
      notification.info({
        message: `Nenhuma propriedade encontrada`,
      });
    }
  }, [propriedade]);

  const switchPanel = useCallback((value: string) => {
    if (value === 'ABATEDOURO') {
      setShowAbatedouroPanel(true);
      setShowPropriedadePanel(false);
    } else if (value === 'PROPRIEDADE') {
      setShowAbatedouroPanel(false);
      setShowPropriedadePanel(true);
    }
  }, []);

  const handleSelectFuncoes = (list: any[]) => {
    form.setFieldsValue({
      representanteEstabelecimento: {
        funcoesNoEstabelecimento: list,
      },
    });
  };

  const handleSelectVinculoEpidemiologico = (list: any[]) => {};

  const clearModalVistorias = useCallback(() => {
    form.setFieldsValue({
      modalVistoria: undefined,
    });
  }, [form]);

  const clearModalNovoEstabelecimentoParaInvestigacao = useCallback(() => {
    form.setFieldsValue({
      modalNovoEstabelecimentoParaInvestigacao: undefined,
    });
    setPropriedadeSelecionada(false);
  }, [form]);

  const handleAddVistoria = useCallback(() => {
    const vistoriaDoModal = form.getFieldValue('modalVistoria');

    const novaVistoria: VistoriaFormVIN.Input = { ...vistoriaDoModal };
    const newList = dataSourceTableVistorias.concat(novaVistoria);
    setDataSourceTableVistorias(newList);

    form.setFieldsValue({ vistoriasFormVIN: dataSourceTableVistorias });

    setShowModalVistorias(false);
    clearModalVistorias();
    form.validateFields(['vistoriasFormVIN']);
    notification.success({
      message: 'Vistoria incluída com sucesso',
    });
  }, [clearModalVistorias, dataSourceTableVistorias, form]);

  const handleAddNovoEstabelecimentoParaInvestigacao = useCallback(() => {
    const novoEstabelecimentoParaInvestigacao = form.getFieldValue(
      'modalNovoEstabelecimentoParaInvestigacao'
    );

    const novoEstabelecimentoParaInvestigacaoAux: NovoEstabelecimentoParaInvestigacao.Input =
      { ...novoEstabelecimentoParaInvestigacao };

    if (dataSourceTableNovoEstabelecimentoParaInvestigacao) {
      const newList = dataSourceTableNovoEstabelecimentoParaInvestigacao.concat(
        novoEstabelecimentoParaInvestigacaoAux
      );
      setDataSourceTableNovoEstabelecimentoParaInvestigacao(newList);
    } else
      setDataSourceTableNovoEstabelecimentoParaInvestigacao([
        novoEstabelecimentoParaInvestigacaoAux,
      ]);

    form.setFieldsValue({
      novoEstabelecimentoParaInvestigacoes:
        dataSourceTableNovoEstabelecimentoParaInvestigacao,
    });

    setShowModalNovoEstabelecimentoParaInvestigacao(false);
    clearModalNovoEstabelecimentoParaInvestigacao();
    form.validateFields(['tableNovoEstabelecimentoParaInvestigacao']);
    notification.success({
      message: 'Estabelecimento incluído com sucesso',
    });
  }, [
    clearModalNovoEstabelecimentoParaInvestigacao,
    dataSourceTableNovoEstabelecimentoParaInvestigacao,
    form,
  ]);

  const removeVistoriaFormVIN = useCallback(
    (vistoria: VistoriaFormVIN.Input) => {
      try {
        setDataSourceTableVistorias(
          dataSourceTableVistorias.filter(
            (vistoriaDS) =>
              vistoriaDS.especie !== vistoria.especie &&
              vistoriaDS.tipoDeAgrupamento !== vistoria.tipoDeAgrupamento
          )
        );

        notification.success({
          message: 'Vistoria removida com sucesso',
        });
      } catch (e) {
        setError(new Error('Erro desconhecido'));
      }
    },
    [dataSourceTableVistorias]
  );

  const removeNovoEstabelecimentoParaInvestigacao = useCallback(
    (estabelecimento: NovoEstabelecimentoParaInvestigacao.Input) => {
      try {
        setDataSourceTableNovoEstabelecimentoParaInvestigacao(
          //@ts-ignore
          dataSourceTableNovoEstabelecimentoParaInvestigacao.filter(
            (novoEstabelecimentoParaInvestigacao) =>
              novoEstabelecimentoParaInvestigacao.propriedade.id !==
              estabelecimento.propriedade.id
          )
        );

        notification.success({
          message: 'Vistoria removida com sucesso',
        });
      } catch (e) {
        setError(new Error('Erro desconhecido'));
      }
    },
    []
  );

  const removePropriedade = useCallback(() => {
    form.setFieldsValue({
      modalNovoEstabelecimentoParaInvestigacao: undefined,
    });
    setPropriedadeSelecionada(false);
  }, [form]);

  if (error) throw error;

  return (
    <>
      <fieldset disabled={formDisabled}>
        <Form<FormFormVINType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={props.formVIN}
        >
          <Form.Item name={'id'} hidden>
            <Input disabled hidden />
          </Form.Item>

          <Typography.Title level={3}>Dados do FormIN</Typography.Title>
          <Divider />

          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item name={['formIN', 'id']} hidden>
                <Input disabled hidden />
              </Form.Item>

              <Form.Item
                label={'Número do Form IN'}
                name={['formIN', 'numero']}
                validateTrigger={'onSearch'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    validator: async (_, numeroFormIN) => {
                      if (!numeroFormIN) {
                        return Promise.reject();
                      }
                    },
                  },
                ]}
              >
                <Input.Search
                  width={'100%'}
                  placeholder={'Informe o número do FormIN'}
                  allowClear={false}
                  disabled={formINSelecionado}
                  loading={loading}
                  inputMode='numeric'
                  autoComplete='off'
                  maxLength={12}
                  addonAfter={
                    <Button
                      //disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeFormIN}
                    />
                  }
                  onSearch={(numero) => {
                    if (!numero) {
                      removeFormIN();
                      setFetching(false);
                    } else fetchFormINByNumero(numero);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                  }}
                  onKeyPress={(event: any) => {
                    let { value } = event.target;

                    value = value.replace(/(\d{7})/, '$1-');
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                    value = value.replace('--', '-');

                    event.currentTarget.value = value;
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={16}>
              <Form.Item
                label={'Propriedade'}
                name={['formIN', 'nomePropriedade']}
                rules={[
                  {
                    validator: async (_) => {
                      if (!formINSelecionado) {
                        return Promise.reject('Nenhum form IN foi Selecionado');
                      }
                    },
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Data da Notificação'}
                name={['formIN', 'dataDaNotificacao']}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Data de Abertura'}
                name={['formIN', 'dataAbertura']}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Data do inicio do Evento'}
                name={['formIN', 'dataInicioEvento']}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Município'}
                name={['formIN', 'municipio', 'nome']}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Motivo inicial da investigação'}
                name={['formIN', 'motivoInicialDaInvestigacao']}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Fonte da investigação'}
                name={['formIN', 'fonteDaNotificacao']}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <br />
          <Typography.Title level={3} style={{ marginBottom: '0px' }}>
            Inspeção
          </Typography.Title>
          <Divider style={{ marginTop: '0px' }} />

          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Número da inspeção'}
                name={['numeroInspecao']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    validator: async (_, quantidade) => {
                      if (!quantidade) {
                        return Promise.reject();
                      }
                      if (isNaN(Number(quantidade))) {
                        return Promise.reject(
                          new Error('O número precisa ser um número')
                        );
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={9999}
                  style={{ width: '100%' }}
                  type={'number'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Data da inspeção'}
                name={'dataInspecao'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(date) => {
                    return date.isAfter(moment().toDate());
                  }}
                  disabled={formDisabled}
                  inputReadOnly
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Tipo estabelecimento'}
                name={'tipoEstabelecimento'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatorio',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione o tipo do estabelecimento'}
                  onChange={switchPanel}
                  disabled={formDisabled}
                  placement={'bottomLeft'}
                >
                  <Select.Option key={'ABATEDOURO'} value={'ABATEDOURO'}>
                    Abatedouro
                  </Select.Option>
                  <Select.Option key={'PROPRIEDADE'} value={'PROPRIEDADE'}>
                    Propriedade
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {showAbatedouroPanel && (
            <AbatedouroPanel form={form} formDisabled={formDisabled} />
          )}
          {showPropriedadePanel && (
            <PropriedadePanel
              form={form}
              formDisabled={formDisabled}
              showHeader
            />
          )}

          {(showAbatedouroPanel || showPropriedadePanel) && <Divider />}

          <Typography.Title level={3}>
            Contato no estabelecimento
          </Typography.Title>
          <Divider />

          <Col xs={24} lg={24}>
            <Form.Item
              label={'Contato é o produtor?'}
              name={'copyProdutorToContato'}
              valuePropName={'checked'}
              hidden={
                form.getFieldValue(['tipoEstabelecimento']) !== 'PROPRIEDADE'
              }
            >
              <Switch
                defaultChecked={false}
                size={'default'}
                disabled={formDisabled}
                onChange={(value) => {
                  if (value) {
                    const produtor: Produtor.Summary = form.getFieldValue([
                      'proprietario',
                    ]);
                    form.setFieldsValue({
                      representanteEstabelecimento: {
                        nome: produtor.nome,
                        telefoneCelular: produtor.endereco?.telefone,
                      },
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Form.Item hidden name={['representanteEstabelecimento', 'id']}>
              <Input disabled />
            </Form.Item>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Nome'}
                name={['representanteEstabelecimento', 'nome']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input maxLength={255} autoComplete='off' />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Telefone fixo'}
                name={['representanteEstabelecimento', 'telefoneFixo']}
              >
                <Input
                  inputMode='tel'
                  autoComplete='off'
                  onKeyPress={validatePhoneInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={'Telefone celular'}
                name={['representanteEstabelecimento', 'telefoneCelular']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input
                  inputMode='tel'
                  autoComplete='off'
                  onKeyPress={validatePhoneInput}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Funções no estabelecimento'}
                name={[
                  'representanteEstabelecimento',
                  'funcoesNoEstabelecimento',
                ]}
                valuePropName={'checked'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  mode='multiple'
                  placeholder={'Selecione as funções'}
                  onChange={handleSelectFuncoes}
                  showSearch={false}
                  placement={'bottomLeft'}
                  disabled={formDisabled}
                  defaultValue={
                    props.formVIN?.representanteEstabelecimento
                      ?.funcoesNoEstabelecimento
                  }
                  maxTagCount={'responsive'}
                  style={{
                    inlineSize: 250,
                    height: 'auto',
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  {listaFuncoes?.map((funcao) => (
                    <Select.Option key={funcao} value={funcao}>
                      {FuncoesNoEstabelecimento.valueOf(funcao)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={3}>
            Assinale os tipos de vínculo epidemiológico que levaram o SVO ao
            estabelecimento
          </Typography.Title>

          <Divider />

          <Row gutter={24}>
            <Col xs={24} lg={24} style={{ width: '100%' }}>
              <Form.Item
                label={'Vínculo epidemiológico'}
                name={['vinculoEpidemiologico']}
                valuePropName={'checked'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Select
                  mode='multiple'
                  placeholder={'Selecione os tipos de vínculo'}
                  onChange={handleSelectVinculoEpidemiologico}
                  showSearch={false}
                  defaultValue={props.formVIN?.vinculoEpidemiologico}
                  placement={'bottomLeft'}
                  disabled={formDisabled}
                  maxTagCount={'responsive'}
                  style={{
                    inlineSize: 250,
                    height: 'auto',
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  {listaTipoVinculoEpidemiologico?.map((tipoVinculo) => (
                    <Select.Option key={tipoVinculo} value={tipoVinculo}>
                      {TipoVinculoEpidemiologico.valueOf(tipoVinculo)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={3}>
            Descrição das atividades realizadas e principais observações
          </Typography.Title>

          <Divider />
          <Row>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Resumo'}
                name={'observacoes'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <TextArea showCount rows={5} maxLength={2000} />
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={3}>
            Informações sobre a vistoria geral e o exame de animais realizados
            durante a inspeção no estabelecimento
          </Typography.Title>

          <Divider />

          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Ausência de animais com sinais clínicos compatíveis'}
                name={'ausenciaDeAnimaisCompativeisComADoencaInvestigada'}
                valuePropName={'checked'}
              >
                <Switch
                  defaultChecked={false}
                  size={'default'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24}>
              <Form.Item
                validateTrigger={'onSubmit'}
                name='vistoriasFormVIN'
                rules={[
                  {
                    validator(_, value) {
                      if (dataSourceTableVistorias.length < 1)
                        return Promise.reject(
                          new Error(
                            'Deve ser informado pelo menos uma vistoria realizada'
                          )
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Table<VistoriaFormVIN.Input>
                  size={'small'}
                  dataSource={dataSourceTableVistorias}
                  rowKey={(record) => record.especie + record.tipoDeAgrupamento}
                  title={() => {
                    return (
                      <Row justify={'space-between'}>
                        <Typography.Title level={5} style={{ color: 'white' }}>
                          Vistorias
                        </Typography.Title>
                        <Button
                          icon={<PlusOutlined />}
                          type={'primary'}
                          onClick={() => {
                            setShowModalVistorias(true);
                          }}
                          disabled={formDisabled}
                        />
                      </Row>
                    );
                  }}
                >
                  <Column
                    dataIndex={['id']}
                    responsive={['xs']}
                    render={(id, vistoria: VistoriaFormVIN.Input) => {
                      return (
                        <Descriptions size={'small'} column={1} bordered>
                          <Descriptions.Item label={'Espécie'}>
                            {Especie.valueOf(
                              listaEspecies.filter(
                                (t) => t === vistoria.especie
                              )[0]
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label={'Agrupamento'}>
                            {TipoDeAgrupamento.valueOf(
                              listaTipoDeAgrupamento.filter(
                                (t) => t === vistoria.tipoDeAgrupamento
                              )[0]
                            )}
                          </Descriptions.Item>

                          <Descriptions.Item label={'Ações'}>
                            <Popconfirm
                              title={'Deseja remover a vistoria?'}
                              disabled={formDisabled}
                              onConfirm={() => {
                                removeVistoriaFormVIN(vistoria);
                              }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                size={'small'}
                                type={'ghost'}
                                danger
                                disabled={formDisabled}
                              />
                            </Popconfirm>
                          </Descriptions.Item>
                        </Descriptions>
                      );
                    }}
                  />

                  <Column
                    dataIndex={['especie']}
                    title='Espécie'
                    responsive={['sm']}
                    render={(especie) =>
                      Especie.valueOf(
                        listaEspecies.filter((t) => t === especie)[0]
                      )
                    }
                  />
                  <Column
                    dataIndex={['tipoDeAgrupamento']}
                    title='Tipo de Agrupamento'
                    responsive={['sm']}
                    render={(tipoDeAgrupamento) =>
                      TipoDeAgrupamento.valueOf(
                        listaTipoDeAgrupamento.filter(
                          (t) => t === tipoDeAgrupamento
                        )[0]
                      )
                    }
                  />

                  <ColumnGroup title={'Total existente'} responsive={['md']}>
                    <Column
                      dataIndex={['totalAgrupamentosExistentes']}
                      title='Agrupamentos'
                      responsive={['md']}
                    />
                    <Column
                      dataIndex={['totalAnimaisExistentes']}
                      title='Animais'
                      responsive={['md']}
                    />
                  </ColumnGroup>

                  <ColumnGroup title={'Total vistoriado'} responsive={['md']}>
                    <Column
                      dataIndex={['totalAgrupamentosVistoriados']}
                      title='Agrupamentos'
                      responsive={['md']}
                    />
                    <Column
                      dataIndex={['totalAnimaisVistoriados']}
                      title='Animais'
                      responsive={['md']}
                    />
                  </ColumnGroup>

                  <ColumnGroup title={'Total examinado'} responsive={['md']}>
                    <Column
                      dataIndex={['totalAgrupamentosExaminados']}
                      title='Agrupamentos'
                      responsive={['md']}
                    />
                    <Column
                      dataIndex={['totalAnimaisExaminados']}
                      title='Animais'
                      responsive={['md']}
                    />
                  </ColumnGroup>

                  <Column
                    dataIndex={['id']}
                    title='Ações'
                    responsive={['md']}
                    width={60}
                    render={(id: number, vistoria: VistoriaFormVIN.Input) => {
                      return (
                        <Popconfirm
                          title={'Deseja remover a vistoria?'}
                          disabled={formDisabled}
                          onConfirm={() => {
                            removeVistoriaFormVIN(vistoria);
                          }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'ghost'}
                            danger
                            disabled={formDisabled}
                          />
                        </Popconfirm>
                      );
                    }}
                  />
                </Table>
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={3}>
            Indicação de novos estabelecimentos para investigação devido a
            vínculo epidemiológico
          </Typography.Title>

          <Divider />

          <Row gutter={24}>
            <Col xs={24} sm={24}>
              <Form.Item
                validateTrigger={'onSubmit'}
                name='novoEstabelecimentoParaInvestigacoes'
              >
                <Table<NovoEstabelecimentoParaInvestigacao.Input>
                  size={'small'}
                  dataSource={
                    dataSourceTableNovoEstabelecimentoParaInvestigacao
                  }
                  rowKey={(record) => record.propriedade.id}
                  title={() => {
                    return (
                      <Row justify={'space-between'}>
                        <Typography.Title level={5} style={{ color: 'white' }}>
                          Estabelecimentos
                        </Typography.Title>
                        <Button
                          icon={<PlusOutlined />}
                          type={'primary'}
                          onClick={() => {
                            setShowModalNovoEstabelecimentoParaInvestigacao(
                              true
                            );
                          }}
                          disabled={formDisabled}
                        />
                      </Row>
                    );
                  }}
                >
                  <Column
                    dataIndex={['id']}
                    responsive={['xs']}
                    render={(
                      id,
                      estabelecimento: NovoEstabelecimentoParaInvestigacao.Input
                    ) => {
                      return (
                        <Descriptions size={'small'} column={1} bordered>
                          <Descriptions.Item label={'Tipo do Vínculo'}>
                            {estabelecimento.listaVinculoEpidemiologico?.map(
                              (tipoVinculo) => (
                                <Tag>
                                  <Typography.Text
                                    ellipsis
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      maxWidth: '200px',
                                    }}
                                  >
                                    {TipoVinculoEpidemiologico.valueOf(
                                      listaTipoVinculoEpidemiologico.filter(
                                        (e) => e === tipoVinculo
                                      )[0]
                                    )}
                                  </Typography.Text>
                                </Tag>
                              )
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label={'Estabelecimento'}>
                            {estabelecimento.propriedade.nome}
                          </Descriptions.Item>

                          <Descriptions.Item label={'Ações'}>
                            <Popconfirm
                              title={'Deseja remover a vistoria?'}
                              disabled={formDisabled}
                              onConfirm={() => {
                                removeNovoEstabelecimentoParaInvestigacao(
                                  estabelecimento
                                );
                              }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                size={'small'}
                                type={'ghost'}
                                danger
                                disabled={formDisabled}
                              />
                            </Popconfirm>
                          </Descriptions.Item>
                        </Descriptions>
                      );
                    }}
                  />

                  <Column
                    dataIndex={['listaVinculoEpidemiologico']}
                    title='Tipo do Vínculo'
                    responsive={['sm']}
                    render={(listaTipoVinculo: any[]) => {
                      return (
                        <>
                          {listaTipoVinculo.map((tipoVinculo) => (
                            <Tag>
                              <Typography.Text
                                ellipsis
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  maxWidth: '200px',
                                }}
                              >
                                {
                                  listaTipoVinculoEpidemiologico.filter(
                                    (e) => e === tipoVinculo
                                  )[0]
                                }
                              </Typography.Text>
                            </Tag>
                          ))}
                        </>
                      );
                    }}
                  />
                  <Column
                    dataIndex={['propriedade']}
                    title='Propriedade'
                    responsive={['sm']}
                    render={(
                      propriedade: NovoEstabelecimentoParaInvestigacao.Input['propriedade']
                    ) => propriedade.nome}
                  />

                  <Column
                    dataIndex={['id']}
                    title='Ações'
                    responsive={['md']}
                    width={60}
                    render={(
                      id: number,
                      estabelecimento: NovoEstabelecimentoParaInvestigacao.Input
                    ) => {
                      return (
                        <Popconfirm
                          title={'Deseja remover o estabelecimento?'}
                          disabled={formDisabled}
                          onConfirm={() => {
                            removeNovoEstabelecimentoParaInvestigacao(
                              estabelecimento
                            );
                          }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'ghost'}
                            danger
                            disabled={formDisabled}
                          />
                        </Popconfirm>
                      );
                    }}
                  />
                </Table>
              </Form.Item>
            </Col>
          </Row>

          <Typography.Title level={3}>
            Identificação, formas de contato e assinatura do médico veterinário
            responsável pelo atendimento
          </Typography.Title>

          <Divider />
          <Row>
            <Col xs={24} lg={24}>
              <VeterinarioPanel form={form} formDisabled={formDisabled} />
            </Col>
          </Row>

          <Row justify={'end'} gutter={24}>
            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  if (formDisabled) navigate(`${online ? '/formvins' : '/'}`);
                  else
                    confirm({
                      title: 'Deseja cancelar o cadastro?',
                      content:
                        'Ao confirmar, todos os dados digitados serão perdidos e você será redirecionado para a lista de Visitas',
                      onOk: () => navigate('/'),
                    });
                }}
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/visitas/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>

      <CustomModal
        centered
        open={showModalVistorias}
        title={'Cadastro de Vistorias'}
        onCancel={() => {
          setShowModalVistorias(false);
          clearModalVistorias();
        }}
        width={750}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Form layout={'vertical'} form={form} autoComplete={'off'}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item label={'id'} name={['modalVistoria', 'id']} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                label={'Espécie'}
                name={['modalVistoria', 'especie']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione uma espécie'}
                  placement={'bottomLeft'}
                  disabled={editingVistoria}
                >
                  {listaEspecies?.map((especie) => (
                    <Select.Option key={especie} value={especie}>
                      {Especie.valueOf(especie)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Tipo de Agrupamento'}
                name={['modalVistoria', 'tipoDeAgrupamento']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  placeholder={'Selecione uma tipo de Agrupamento'}
                  placement={'bottomLeft'}
                  disabled={editingVistoria}
                >
                  {listaTipoDeAgrupamento?.map((tipoDeAgrupamento) => (
                    <Select.Option
                      key={tipoDeAgrupamento}
                      value={tipoDeAgrupamento}
                    >
                      {TipoDeAgrupamento.valueOf(tipoDeAgrupamento)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Space
              style={{ width: '100%' }}
              direction={xs ? 'vertical' : 'horizontal'}
            >
              <Col xs={24} lg={24}>
                <Card title={'Total existentes'} style={{ width: '100%' }}>
                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Agrupamentos'}
                      name={['modalVistoria', 'totalAgrupamentosExistentes']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Animais'}
                      name={['modalVistoria', 'totalAnimaisExistentes']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                        autoComplete='off'
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>

              <Col xs={24} lg={24}>
                <Card title={'Total examinados'} style={{ width: '100%' }}>
                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Agrupamentos'}
                      name={['modalVistoria', 'totalAgrupamentosExaminados']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                        autoComplete='off'
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Animais'}
                      name={['modalVistoria', 'totalAnimaisExaminados']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                        autoComplete='off'
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>

              <Col xs={24} lg={24}>
                <Card title={'Total vistoriados'} style={{ width: '100%' }}>
                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Agrupamentos'}
                      name={['modalVistoria', 'totalAgrupamentosVistoriados']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                        autoComplete='off'
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={24}>
                    <Form.Item
                      initialValue={0}
                      label={'Animais'}
                      name={['modalVistoria', 'totalAnimaisVistoriados']}
                      rules={[
                        {
                          required: true,
                          message: 'O campo é obrigatório',
                        },
                        {
                          validator: async (_, quantidade) => {
                            if (quantidade === 0) return Promise.resolve();

                            if (!quantidade) {
                              return Promise.reject();
                            }
                            if (isNaN(Number(quantidade))) {
                              return Promise.reject(
                                new Error('A quantidade precisa ser um número')
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={9999}
                        style={{ width: '100%' }}
                        type={'number'}
                        autoComplete='off'
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
            </Space>
          </Row>
        </Form>

        <Divider />
        <Row gutter={24} justify={'end'}>
          <Col xs={12} lg={6}>
            <Button
              style={{ width: '100%' }}
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalVistorias(false);
                clearModalVistorias();
              }}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={12} lg={6}>
            <Button
              style={{ width: '100%' }}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                form.validateFields([
                  ['modalVistoria', 'especie'],
                  ['modalVistoria', 'tipoDeAgrupamento'],
                  ['modalVistoria', 'totalAgrupamentosExistentes'],
                  ['modalVistoria', 'totalAnimaisExistentes'],
                  ['modalVistoria', 'totalAgrupamentosVistoriados'],
                  ['modalVistoria', 'totalAnimaisVistoriados'],
                  ['modalVistoria', 'totalAgrupamentosExaminados'],
                  ['modalVistoria', 'totalAnimaisExaminados'],
                ]);

                const especieVistoria = form.getFieldValue([
                  'modalVistoria',
                  'especie',
                ]);

                if (!especieVistoria) return;

                handleAddVistoria();
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </CustomModal>

      <CustomModal
        centered
        open={showModalNovoEstabelecimentoParaInvestigacao}
        title={'Cadastro de Novos Estabelecimentos para investigação'}
        onCancel={() => {
          setShowModalNovoEstabelecimentoParaInvestigacao(false);
          clearModalNovoEstabelecimentoParaInvestigacao();
        }}
        width={750}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Form layout={'vertical'} form={form} autoComplete={'off'}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'id'}
                name={['modalNovoEstabelecimentoParaInvestigacao', 'id']}
                hidden
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={'Vínculo epidemiológico'}
                name={[
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'listaVinculoEpidemiologico',
                ]}
                valuePropName={'checked'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  mode='multiple'
                  placeholder={'Selecione os tipos de vínculo'}
                  onChange={handleSelectVinculoEpidemiologico}
                  showSearch={false}
                  placement={'bottomLeft'}
                  style={{
                    inlineSize: 250,
                    height: 'auto',
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  {listaTipoVinculoEpidemiologico?.map((tipoVinculo) => (
                    <Select.Option key={tipoVinculo} value={tipoVinculo}>
                      {TipoVinculoEpidemiologico.valueOf(tipoVinculo)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                label={'Código da propriedade'}
                name={[
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'propriedade',
                  'id',
                ]}
                validateTrigger={'onSearch'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    validator: async (_, idPropriedade) => {
                      if (!idPropriedade) {
                        return Promise.reject();
                      }
                      if (isNaN(Number(idPropriedade))) {
                        return Promise.reject(
                          new Error('O código precisa ser um número')
                        );
                      }
                    },
                  },
                ]}
              >
                <Input.Search
                  width={'100%'}
                  placeholder={'Informe o código da propriedade'}
                  disabled={propriedadeSelecionada || mode === 'VIEW'}
                  allowClear={false}
                  loading={fetching}
                  inputMode='numeric'
                  addonAfter={
                    <Button
                      //disabled={props.formDisabled}
                      icon={<DeleteOutlined />}
                      danger
                      onClick={removeFormIN}
                    />
                  }
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onSearch={(id) => {
                    if (!id) {
                      removePropriedade();
                      setFetching(false);
                    } else fetchPropriedadeByCodigo(id);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={16}>
              <Form.Item
                label={'Propriedade'}
                name={[
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'propriedade',
                  'nome',
                ]}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Município'}
                name={[
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'propriedade',
                  'municipio',
                  'nome',
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Unidade'}
                name={[
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'propriedade',
                  'unidade',
                  'nome',
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider />
        <Row gutter={24} justify={'end'}>
          <Col xs={12} lg={6}>
            <Button
              style={{ width: '100%' }}
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalNovoEstabelecimentoParaInvestigacao(false);
                clearModalNovoEstabelecimentoParaInvestigacao();
              }}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={12} lg={6}>
            <Button
              style={{ width: '100%' }}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                form.validateFields([
                  [
                    'modalNovoEstabelecimentoParaInvestigacao',
                    'listaVinculoEpidemiologico',
                  ],
                  [
                    'modalNovoEstabelecimentoParaInvestigacao',
                    'propriedade',
                    'id',
                  ],
                ]);

                if (!propriedadeSelecionada) {
                  notification.warn({
                    message: 'Nenhuma propriedade foi selecionada',
                  });
                  return;
                }

                const listaVinculo = form.getFieldValue([
                  'modalNovoEstabelecimentoParaInvestigacao',
                  'listaVinculoEpidemiologico',
                ]);

                if (!listaVinculo) return;
                handleAddNovoEstabelecimentoParaInvestigacao();
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </CustomModal>

      <CustomModal
        centered
        open={showModalFinalizacao}
        width={750}
        footer={null}
        destroyOnClose
        closable={false}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          bordered={false}
        >
          <Row
            gutter={24}
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Col xs={24} sm={24}>
              <Typography.Paragraph>
                {online
                  ? 'Form VIN salvo com sucesso'
                  : 'Form VIN salvo localmente'}
              </Typography.Paragraph>
            </Col>
            <Col xs={24} sm={24}>
              <Typography.Paragraph style={{ fontWeight: 'bold' }}>
                {online
                  ? dadosFormVINModalFinalizacao?.numero
                  : 'Ela será enviada para o servidor quando houver conexão novamente'}
              </Typography.Paragraph>
            </Col>
          </Row>

          <Row
            align='middle'
            style={{
              justifyContent: 'center',
            }}
            gutter={24}
          >
            <Col>
              <Button
                onClick={() => navigate(`${online ? '/formvins' : '/'}`)}
                type={'primary'}
              >
                {' '}
                Voltar{' '}
              </Button>
            </Col>
            <Col>
              {online && (
                <Button
                  onClick={() =>
                    navigate(
                      `/formvins/visualizar/${dadosFormVINModalFinalizacao?.id}`
                    )
                  }
                  type={'primary'}
                >
                  {' '}
                  Visualizar{' '}
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </CustomModal>
    </>
  );
}
