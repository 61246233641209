import { ToolOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import SendEmailService from '../../sdk/services/SIZ-API/SendEmail.service';
import AuthorizationService from '../../core/auth/Authorization.service';
import { ServiceIDBPayloadInput } from '../../sdk/services/indexeddb/ServiceIDB';
import moment from 'moment';
import { CustomModal } from './CustomModal';

type TroubleshootingButtonProps = {
  component: 'FVV' | 'FVER' | 'TFR' | 'LIA';
  data: ServiceIDBPayloadInput[] | undefined;
};

export default function TroubleshootingButton(
  props: TroubleshootingButtonProps
) {
  const [showModalTroubleShooting, setShowModalTroubleShooting] =
    useState(false);
  const [form] = Form.useForm();

  useEffect(() => {}, [showModalTroubleShooting]);

  useEffect(() => {}, [props.data]);

  const buildCorpoEmail = useCallback(() => {
    let corpo = '';
    let dataClean = undefined;
    const userMessage = form.getFieldValue('userMessage');

    if (props.component === 'FVER') {
      dataClean = props.data?.map((fver) => {
        return {
          ...fver,
          payload: {
            ...fver.payload,
            assinatura: undefined,
          },
        };
      });
    }
    corpo = `<div>
      Usuário: ${AuthorizationService.getUsuarioSIZ()?.nome}
      <br/>
      ${AuthorizationService.getUsuarioSIZ()?.cpf}<br/>${
      AuthorizationService.getUsuarioSIZ()?.email
    }
    </div>`;

    if (userMessage) {
      corpo = corpo.concat(`
      <br/>
      <div>
        Mensagem do usuário:
        <br/>
        ${userMessage}
      </div>
      `);
    }

    corpo = corpo.concat(`<br/><br/><br/>
    <div>
      ${JSON.stringify(dataClean ? dataClean : props.data)}
    </div>`);

    return corpo;
  }, [form, props]);

  const enviarEmail = useCallback(() => {
    setShowModalTroubleShooting(false);
    SendEmailService.send({
      assunto: `Troubleshooting ${props.component} ${
        AuthorizationService.getUsuarioSIZ()?.nome
      } ${moment().format('DD/MM/YYYY HH:mm')}`,
      corpo: buildCorpoEmail(),
      destinatario: 'leonardoassis@indea.mt.gov.br',
    })
      .then(() => {
        notification.success({
          message: 'Email enviado com sucesso!',
          description: 'Avisaremos quando nossa análise for concluída',
        });
      })
      .catch((e) => {
        notification.error({
          message: 'Não foi possível enviar o email.',
          description: `Envie o código ao desenvolvedor: ${e.message}`,
        });
      });
  }, [props, buildCorpoEmail]);

  return (
    <>
      <Button
        icon={<ToolOutlined />}
        size={'small'}
        type={'primary'}
        onClick={() => {
          setShowModalTroubleShooting(true);
        }}
      />
      <CustomModal
        open={showModalTroubleShooting}
        closable={false}
        bodyStyle={{ padding: 0 }}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              type='default'
              style={{ width: '50%' }}
              onClick={() => {
                setShowModalTroubleShooting(false);
              }}
            >
              Fechar
            </Button>
            <Button
              type='primary'
              style={{ width: '50%' }}
              onClick={enviarEmail}
            >
              Enviar email
            </Button>
          </div>
        }
        title={'Ajuda'}
      >
        <Form
          layout={'vertical'}
          form={form}
          size={'small'}
          autoComplete={'off'}
        >
          <Typography.Paragraph style={{ textAlign: 'justify' }}>
            Coletamos alguns dados sobre os {props.component} salvos no seu
            dispositivo e vamos enviar ao nosso time para análise.
          </Typography.Paragraph>

          <Form.Item
            label={'Deseja enviar uma mensagem adicional?'}
            name={'userMessage'}
          >
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 8 }}
              showCount
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  );
}
