import {
  Affix,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Steps,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { FVER } from '../../../sdk/@types';
import * as liaActions from '../../../core/store/LIA.slice';

import DistanciasExternas from './DistanciasExternas';
import DistanciasInternas from './DistanciasInternas';
import ControleAcesso from './controleAcesso';
import TerrenoLocalizacao from './terrenoLocalizacao';
import InstalacoesEquipamentos from './InstalacoesEquipamentos';
import MedidasSanitarias from './MedidasSanitarias';
import RiscoBioseguridade from './riscoBioseguridade';
import Documentos from './Documentos';
import ParecerFinal from './ParecerFinal';
import * as LIAActions from '../../../core/store/LIA.slice';
import moment, { Moment } from 'moment';
import useAssinaturaBase64Image from '../../../core/hooks/useAssinaturaBase64Image';
import AuthorizationService from '../../../core/auth/Authorization.service';
import VeterinarioIDBService from '../../../sdk/services/indexeddb/VeterinarioIDB.service';
import PanelFVER from '../FVER/PanelFVER';
import InformacaoGranja from './InformacaoGranja';
import EnvConfig from '../../EnvConfig';
import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import useFVER from '../../../core/hooks/useFVER';
import { CustomModal } from '../../components/CustomModal';
import { CloseOutlined, SelectOutlined } from '@ant-design/icons';
import FilterPanelFVER from '../FVER/FilterPanelFVER';
import { RootState } from '../../../core/store';

import * as FVERActions from '../../../core/store/FVER.slice';
import { TipoEstabelecimento } from '../../../core/enums/TipoEstabelecimento';
import { LIA } from '../../../sdk/@types/LIA';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import useDevOptions from '../../../core/hooks/useDevOptions';

type FormLIAType = {
  dataParecer?: Moment;
  lia: LIA.Request;
} & Omit<LIA.Request, 'dataParecer'>;

type FormLIAProps = {
  lia?: FormLIAType;
};
type StateStep = {
  step: number;
  state: boolean;
};

export default function FormLIA(props: FormLIAProps) {
  const { online } = useNavigatorStatus();
  const params = useParams<{ id: string; local?: string; problem?: string }>();
  const { xs, lg } = useBreakpoint();
  const { useMock } = useDevOptions();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [form] = Form.useForm<FormLIAType>();
  const [shouldSaveOffline, setShouldSaveOffline] = useState<boolean>(false);
  const { firstOpening, mode, setFirstOpening, setLoading } = useLoadingPage();

  const fvers = useSelector((state: RootState) => state.fver.fvers);
  const query = useSelector((state: RootState) => state.fver.query);
  const fetching = useSelector((state: RootState) => state.fver.fetching);

  const [, setError] = useState<Error>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stateSteps, setStateSteps] = useState<StateStep[]>(
    Array(10)
      .fill({})
      .map((_, i) => ({ step: i, state: false }))
  );

  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();

  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);
  const [fverNaoEncontrado, setFVERNaoEncontrado] = useState(false);

  usePrompt(!showModalFinalizacao);

  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const setAllStepsToComplete = () => {
    const updatedStateSteps = stateSteps.map((_, i) => ({
      step: i,
      state: true,
    }));
    setStateSteps(updatedStateSteps);
  };

  const saveLIALocal = useCallback(
    async (lia: LIA.Request, problema_rede?: boolean) => {
      try {
        const actionPromise = dispatch(
          LIAActions.updateLIAOffline({
            id: Number(params.id),
            lia,
          })
        );

        //@ts-ignore
        actionPromise.then((actionResult) => {
          //@ts-ignore
          if (actionResult.type.includes('rejected')) {
            notification.error({
              message: 'Não foi possível salvar o Laudo de Inspeção',
              //@ts-ignore
              description: actionResult.payload.message,
            });
          }
          //@ts-ignore
          else if (actionResult.type.includes('fulfilled')) {
            //@ts-ignore
            if (actionResult.payload) {
              setDadosModalFinalizacao({
                id: Number(params.id),
                numero: '',
                local: true,
              });

              setShowModalFinalizacao(true);
            }
          }
        });
      } catch (e: any) {
        notification.error({
          message:
            'Não foi possível atualizar o Laudo de Inspeção no dispositivo',
          description: e.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [params, dispatch, setLoading]
  );

  const saveLIAOnline = useCallback(
    async (lia: LIA.Request) => {
      try {
        const actionPromise = dispatch(
          //@ts-ignore
          lia.id
            ? LIAActions.updateLIA({
                id: Number(params.id),
                lia: lia,
              })
            : LIAActions.insertLIA(lia)
        );

        //@ts-ignore
        actionPromise.then((actionResult) => {
          //@ts-ignore
          if (actionResult.type.includes('rejected')) {
            notification.error({
              message: 'Não foi possível salvar o Laudo de Inspeção',
              //@ts-ignore
              description: actionResult.payload.message,
            });
          }
          //@ts-ignore
          else if (actionResult.type.includes('fulfilled')) {
            //@ts-ignore
            if (actionResult.payload) {
              //@ts-ignore
              const lia = actionResult.payload;
              setDadosModalFinalizacao({
                id: Number(lia.id),
                numero: lia.numero,
              });

              setShowModalFinalizacao(true);
            }
          }
        });
      } catch (e: any) {
        console.log('e.message', e);
        console.log(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  const insertLoggedUser = useCallback(async () => {
    if (AuthorizationService.getUsuarioSIZ()) {
      await VeterinarioIDBService.getByCpf(
        //@ts-ignore
        AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
      ).then((veterinario) => {
        if (veterinario) {
          form.setFieldsValue({
            veterinario: veterinario.payload,
          });
        }
      });
    }
  }, [form]);

  const { fetchFVERById, fetchFVERByCodigoVerificador, fver } = useFVER();

  const mockLIA = useCallback(() => {
    form.setFieldsValue({
      registro: false,
      manutencaoRegistro: true,
      tipoExploracao: 'POSTURA_COM_RECRIA',
      quantidadeNucleoRecria: 34,
      quantidadeNucleoProducao: 3,
      quantidadeAviarioRecria: 23,
      quantidadeAviarioProducao: 667,
      capacidadeTotalAlojamento: 564,
      empresaIntegradora: {
        id: 9948,
        nome: 'FRIGORIFICO CASTELO',
        cpfCnpj: '06194582000170',
        endereco: {
          id: 9948,
          logradouro: 'ROD. MT 325, S/N',
          numero: 'S/N',
          bairro: 'SETOR OESTE',
          municipio: {
            id: 5105101,
            nome: 'JUARA',
            uf: {
              id: 51,
              nome: 'MATO GROSSO',
              sigla: 'MT',
            },
          },
        },
      },
      tipoServicoDeInspecao: 'SISE',
      numeroServicoDeInspecao: '200',
      todosAviariosPertencemAoProprietario: undefined,
      responsavelAviario: undefined,
      nomeResponsavelAviario: undefined,
      vinculoComProprietario: undefined,
      distanciaMinima3kmMatrizeiros_1_1: 'NAO',
      distanciaMatrizeiros_1_1: '2km',
      distanciaMinima3kmAvesOrnamentais_1_2: 'NAO',
      distanciaAvesOrnamentais_1_2: '3km',
      distanciaMinima3kmEstabelecimentoEnsino_1_3: 'NAO',
      distanciaEstabelecimentoEnsino_1_3: '2km',
      distanciaMinima3kmIncubatorios_1_4: 'NAO',
      distanciaIncubatorios_1_4: '33km',
      distanciaMinima10kmCriacaoDeAves_1_5: 'NAO',
      distanciaCriacaoDeAves_1_5: '10km',
      distanciaMinima10kmCriacoesAteMilAves_1_6: 'NAO',
      distanciaCriacoesAteMilAves_1_6: '12000',
      distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7: 'NAO',
      distanciaEntreAvesCorteAvesPoedeiras_1_7: '2.5km',
      distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8: 'NAO',
      distanciaEntreAvesCortesDiferentesIntegradoras_1_8: '3.2km',
      distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9: 'NAO',
      distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9: '100m',
      distanciaMinima1kmGranjaAvesPoedeiras_1_10: 'NAO',
      distanciaGranjaAvesPoedeiras_1_10: '3km',
      distanciaMinima3kmAviarioFabricaRacao_1_11: 'NAO',
      distanciaAviarioFabricaRacao_1_11: '100m',
      distanciaMinima3kmIndustriaProcessamentoProdutos_1_12: 'NAO',
      distanciaIndustriaProcessamentoProdutos_1_12: '2km',
      distanciaMinima3kmAterroSanitarioLixao_1_13: 'NAO',
      distanciaAterroSanitarioLixao_1_13: '100m',
      distanciaMinima3kmLagoaResiduo_1_14: 'NAO',
      distanciaLagoaResiduo_1_14: '2km',
      distanciaMinima1kmBiodigestores_1_15: 'NAO',
      distanciaBiodigestores_1_15: '34km',
      distanciaMinima4kmCriacaoDeAves_1_16: 'NAO',
      distanciaCriacaoDeAves_1_16: 'fdsfds',
      observacoesDistanciasExternas_1_17: 'teste',
      distanciaMinima5mDaCercaIsolamento_2_1: 'NAO',
      distanciaDaCercaIsolamento_2_1: '3km',
      distanciaMinima20mDasResidencias_2_2: 'SIM',
      distanciaDasResidencias_2_2: undefined,
      distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3: 'NA',
      distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3: undefined,
      distanciaMinima200mEntreNucleoAvesCorte_2_4: 'SIM',
      distanciaEntreNucleoAvesCorte_2_4: undefined,
      distanciaMinima100mDaEstradaVicinal_2_5: 'NA',
      distanciaDaEstradaVicinal_2_5: undefined,
      distanciaMinima30mDosLimitesPerifericos_2_6: 'SIM',
      distanciaDosLimitesPerifericos_2_6: undefined,
      distanciaMinima500mDaFabricaRacaoPropria_2_7: 'NA',
      distanciaDaFabricaRacaoPropria_2_7: undefined,
      distanciaMinima600mEntreNucleoDeAvesESuinos_2_8: 'SIM',
      distanciaEntreNucleoDeAvesESuinos_2_8: undefined,
      distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9: 'NAO',
      distanciaEntreNucleoAvesEOutrasEspecies_2_9: '120m',
      observacoesDistanciasInternas: 'teste 2',
      terrenoELocalizacao: 'SIM',
      observacoesTerrenoELocalizacao: 'teste 1',
      granjaPossuiSinaisDeAviso_4_1: 'SIM',
      granjaPossuiSinaisDeAvisoEspecificacao_4_1: undefined,
      realizaControleDeTransitoDeVeiculos_4_2: 'SIM',
      realizaControleDeTransitoDeVeiculosEspecificacao_4_2: undefined,
      executaProcedimentosParaDesinfeccaoDeVeiculos_4_3: 'SIM',
      executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3: undefined,
      realizaControleDeAcessoDePessoas_4_4: 'SIM',
      realizaControleDeAcessoDePessoasEspecificacao_4_4: undefined,
      granjaPossuiBarreiraSanitaria_4_5: 'SIM',
      granjaPossuiBarreiraSanitariaEspecificacao_4_5: undefined,
      ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6:
        'SIM',
      ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6:
        undefined,
      funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7: 'SIM',
      funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7:
        undefined,
      granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8: 'NAO',
      granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8:
        'teste4',
      observacoesControleAcessoDePessoas: 'teste 4.8',
      instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1: 'SIM',
      instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1:
        undefined,
      pisoFeitoTerraCompactadaOuCimentado_5_2: 'SIM',
      pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2: undefined,
      muretaDeAlvenariaAdequadoParaLimpeza_5_3: 'SIM',
      muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3: undefined,
      malhaDaTelaDosAviariosAteUmaPolegada_5_4: 'SIM',
      malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4: undefined,
      coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5: 'SIM',
      coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5: undefined,
      possuemNebulizadoresEOuSistemaDeVentilacao_5_6: 'SIM',
      possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6: undefined,
      bebedourosEComedourosDeFacilManejo_5_7: 'SIM',
      bebedourosEComedourosDeFacilManejoEspecificacao_5_7: undefined,
      possuiCercaDeIsolamentoMimino1m_5_8: 'SIM',
      possuiCercaDeIsolamentoMimino1mEspecificacao_5_8: undefined,
      siloDeRacaoFeitoDeMaterialIpermeavel_5_9: 'SIM',
      siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9: undefined,
      ausenciaDeArvoresAtrativasDePassaros_5_10: 'SIM',
      ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10: undefined,
      adotaSistemaDeCriacaoAoArLivre_5_11: 'SIM',
      adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11: undefined,
      observacoesInstalacoesEquipamentos_5_12: 'teste 5.112',
      adotaVazioSanitario_6_1: 'SIM',
      adotaVazioSanitarioEspecificacao_6_1: undefined,
      adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2: 'SIM',
      adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2:
        undefined,
      executaProgramaDeLimpezaEDesinfeccao_6_3: 'SIM',
      executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3: undefined,
      possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4: 'SIM',
      possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4: undefined,
      mantemRegistroDeControleDePragas_6_5: 'SIM',
      mantemRegistroDeControleDePragasEspecificacao_6_5: undefined,
      realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6: 'SIM',
      realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6: undefined,
      aguaUtilizadaETratadaComCloro_6_7: 'SIM',
      aguaUtilizadaETratadaComCloroEspecificacao_6_7: undefined,
      mantemRegistroDasAtividadesDeTransitoDeAves_6_8: 'SIM',
      mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8: undefined,
      mantemRegistroDasAcoesSanitariasExecutadas_6_9: 'SIM',
      mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9: undefined,
      mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10: 'SIM',
      mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10:
        undefined,
      anotacaoDeRTDentroDoPrazoDeValiade_6_11: 'SIM',
      anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11: undefined,
      observacoesMedidasSanitarias_6_12: 'teste 6.12',
      houveNecessidadeDeAvaliacaoDeRisco_7_1: 'SIM',
      riscoProporcionadoPelaGranja_7_1_1: 'DESPREZIVEL',
      necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2: 'SIM',
      medidasDeBiosseguridadeCompensatorias: 'teste',
      possuiRequerimentoSolicitacao_8_1: 'SIM',
      requerimentoSolicitacaoDescricao_8_1: undefined,
      possuiCadastroProprietario_8_2: 'SIM',
      cadastroProprietarioDescricao_8_2: undefined,
      possuiResponsabilidadeTecnica_8_3: 'SIM',
      responsabilidadeTecnicaDescricao_8_3: undefined,
      possuiPlantaLocalizacaoPropriedade_8_4: 'SIM',
      plantaLocalizacaoPropriedadeDescricao_8_4: undefined,
      possuiPlantaBaixaEstabelecimento_8_5: 'SIM',
      plantaBaixaEstabelecimentoDescricao_8_5: undefined,
      possuiMemorialMedidasHigienicoSanitarias_8_6: 'SIM',
      memorialMedidasHigienicoSanitariasDescricao_8_6: undefined,
      possuiDocumentoComprobatorioQualidadeAgua_8_7: 'SIM',
      documentoComprobatorioQualidadeAguaDescricao_8_7: undefined,

      statusAssinatura: 'ASSINADO_FISICAMENTE',
      veterinario: undefined,
      nomeVeterinario: undefined,
      parecer: 'FAVORAVEL_MANUTENCAO_REGISTRO',
    });

    setStateSteps(
      Array(10)
        .fill({})
        .map((_, i) => ({ step: i, state: true }))
    );
  }, [form]);

  useEffect(() => {
    setFirstOpening(true);

    if (props.lia?.assinatura) setAssinaturaBase64Image(props.lia.assinatura);

    if (mode === 'EDIT' || mode === 'VIEW') {
      setAllStepsToComplete();

      if (!params.local) {
        fetchFVERById(
          //@ts-ignore
          props.lia?.fver?.id
        ).catch((e) => {
          setError(new Error(e.message));
        });
      } else {
        if (props.lia?.fver?.codigoVerificador)
          fetchFVERByCodigoVerificador(
            props.lia?.fver?.codigoVerificador,
            params.local ? true : false
          ).catch((e) => {
            setError(new Error(e.message));
          });
      }
    }

    if (mode === 'CREATE') {
      if (useMock) mockLIA();
      insertLoggedUser();
    }
  }, [mode]);

  useEffect(() => {
    if (params.local) {
      setLoading(false);
      if (firstOpening)
        if (params.problem) {
          setFVERNaoEncontrado(true);
          setLoading(false);
        }
    }
  }, [firstOpening, params, props.lia, setLoading, form]);

  useEffect(() => {
    if (mode === 'CREATE') {
      //procurar visita on line
      fetchFVERById(Number(params.id), params.local ? true : false)
        .catch((e) => {
          setError(new Error(e.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetchFVERById]);

  useEffect(() => {
    if (params.problem && fverNaoEncontrado) {
      form.setFieldValue('fver', fver);
    }
  }, [fver]);

  useEffect(() => {
    if (!params.local)
      if (mode === 'EDIT' || mode === 'VIEW') {
        dispatch(
          //@ts-ignore
          liaActions.fetchLIAById(Number(props.lia?.id))
        );
      }
  }, [mode, dispatch, params.id, params.local]);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);
  }, [dispatch, mode, params]);

  const { Step } = Steps;
  const [current, setCurrent] = useState(0);

  enum content {
    INFORMACOES_DA_GRANJA_CADASTRO_EXPLORACAO,
    DISTANCIA_EXTERNA,
    DISTANCIA_INTERNA,
    TERRENO_LOCALIZACAO,
    CONTROLE_ACESSO,
    INSTALACOES_EQUIPAMENTOS,
    MEDIDAS_SANITARIAS,
    RISCO_BIOSEGURIDADE,
    DOCUMENTOS,
    PARECER_FINAL,
  }
  const steps = [
    {
      title: 'Informações da Granja',
      content: content.INFORMACOES_DA_GRANJA_CADASTRO_EXPLORACAO,
    },
    {
      title: 'Distâncias Externas',
      content: content.DISTANCIA_EXTERNA,
    },
    {
      title: 'Distancias Internas',
      content: content.DISTANCIA_INTERNA,
    },
    {
      title: 'Terreno e Localização',
      content: content.TERRENO_LOCALIZACAO,
    },
    {
      title: 'Controle de acesso',
      content: content.CONTROLE_ACESSO,
    },
    {
      title: 'Instalações e equipamentos',
      content: content.INSTALACOES_EQUIPAMENTOS,
    },
    {
      title: 'Medidas Sanitárias',
      content: content.MEDIDAS_SANITARIAS,
    },
    {
      title: 'Risco/ Bioseguridade',
      content: content.RISCO_BIOSEGURIDADE,
    },
    {
      title: 'Documentos para protocolação',
      content: content.DOCUMENTOS,
    },
    {
      title: 'Parecer Final',
      content: content.PARECER_FINAL,
    },
  ];

  const getStatus = (atual: number) => {
    if (mode === 'EDIT' || mode === 'VIEW') return 'finish';

    let state = stateSteps[atual].state;
    if (current === atual) return 'process';
    if (state) return 'finish';
    if (current > atual) return 'error';
    return 'wait';
  };

  const stepsMenu = (
    direction: 'vertical' | 'horizontal',
    labelPlacement: 'vertical' | 'horizontal'
  ) => {
    const updateStateSteps = (currentIndex: number) => {
      const updatedStateSteps = [...stateSteps];
      updatedStateSteps[currentIndex] = { step: currentIndex, state: true };
      setStateSteps(updatedStateSteps);
    };

    const handleStepChange = async (value: number) => {
      if (value < current) {
        setCurrent(value);
      } else {
        try {
          await form.validateFields();
          updateStateSteps(current);
          setCurrent(value);
          window.location.replace('#');
        } catch (error) {
          notification.error({
            message: 'Alguns campos obrigatórios não foram preenchidos',
            description: 'Por favor, revise o formulário',
          });
        }
      }
    };
    return (
      <Steps
        current={current}
        direction={direction}
        labelPlacement={labelPlacement}
        onChange={handleStepChange}
        style={{ paddingBottom: '24px' }}
      >
        {steps.map((item, atual) => (
          <Step
            key={item.title}
            title={item.title}
            status={getStatus(atual)}
            onClick={() => {
              onClose();
            }}
          />
        ))}
      </Steps>
    );
  };
  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleStepChange = async (value: number) => {
    if (value < current) {
      setCurrent(value);
    } else {
      try {
        await form.validateFields();
        updateStateSteps(current);
        setCurrent(value);
      } catch (error) {
        notification.error({
          message: 'Alguns campos obrigatórios não foram preenchidos',
          description: 'Por favor, revise o formulário',
        });
      }
    }
  };

  const updateStateSteps = useCallback(
    (currentStep: number) => {
      setStateSteps(
        stateSteps.map((step, index) => {
          if (index === currentStep) {
            return { ...step, state: true };
          }
          return step;
        })
      );
    },
    [stateSteps]
  );

  const handleFormSubmit = useCallback(
    async (formParam: FormLIAType) => {
      const manutencaoRegistro = form.getFieldValue('manutencaoRegistro');
      if (manutencaoRegistro === true) {
        form.setFieldsValue({
          possuiRequerimentoSolicitacao_8_1: undefined,
          requerimentoSolicitacaoDescricao_8_1: undefined,
          possuiCadastroProprietario_8_2: undefined,
          cadastroProprietarioDescricao_8_2: undefined,
          possuiPlantaLocalizacaoPropriedade_8_4: undefined,
          plantaLocalizacaoPropriedadeDescricao_8_4: undefined,
          possuiPlantaBaixaEstabelecimento_8_5: undefined,
          plantaBaixaEstabelecimentoDescricao_8_5: undefined,
          possuiMemorialMedidasHigienicoSanitarias_8_6: undefined,
          memorialMedidasHigienicoSanitariasDescricao_8_6: undefined,
        });
      }
      if (!manutencaoRegistro) {
        const camposDocumentos = [
          'possuiRequerimentoSolicitacao_8_1',
          'possuiCadastroProprietario_8_2',
          'possuiResponsabilidadeTecnica_8_3',
          'possuiPlantaLocalizacaoPropriedade_8_4',
          'possuiPlantaBaixaEstabelecimento_8_5',
          'possuiMemorialMedidasHigienicoSanitarias_8_6',
          'possuiDocumentoComprobatorioQualidadeAgua_8_7',
        ];

        for (let campo of camposDocumentos) {
          if (!form.getFieldValue(campo)) {
            notification.error({
              message: 'Campos obrigatórios não preenchidos',
              description: `Por favor, preencha todos os campos na seção Documentos.`,
            });
            return;
          }
        }
      }
      setLoading(true);
      updateStateSteps(current);
      form.validateFields().then(() => {
        const newStateSteps = [...stateSteps];
        newStateSteps[9] = {
          step: 9,
          state: true,
        };
        setStateSteps(newStateSteps);
      });

      const fverDTO = params.problem
        ? fver
        : {
            codigoVerificador: fver!.codigoVerificador,
            dataDaVisita: fver!.dataDaVisita,
            id: fver!.id!,
            listChavePrincipalVisitaPropriedadeRural:
              fver!.listChavePrincipalVisitaPropriedadeRural,
            placaVeiculo: fver!.placaVeiculo,
            propriedade: fver!.propriedade!,
            proprietario: fver?.proprietario,
            resumo: fver!.resumo,
            servidores: fver!.servidores,
            setor: fver!.setor,
            status: fver!.status,
            tipoEstabelecimento: fver!.tipoEstabelecimento,
          };

      const formLIADTO: LIA.Request = {
        ...formParam,
        //@ts-ignore
        id: form.getFieldValue('id'),
        //@ts-ignore
        fver: fverDTO,
        registro: form.getFieldValue('registro'),
        manutencaoRegistro: form.getFieldValue('manutencaoRegistro'),
        veterinario: form.getFieldValue('veterinario'),
        adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2:
          form.getFieldValue(
            'adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizadosEspecificacao_6_2'
          ),
        adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2:
          form.getFieldValue(
            'adotaProcedimentoAdequadoParaDestinoDeProdutosUtilizados_6_2'
          ),
        adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11: form.getFieldValue(
          'adotaSistemaDeCriacaoAoArLivreEspecificacao_5_11'
        ),
        adotaSistemaDeCriacaoAoArLivre_5_11: form.getFieldValue(
          'adotaSistemaDeCriacaoAoArLivre_5_11'
        ),
        adotaVazioSanitarioEspecificacao_6_1: form.getFieldValue(
          'adotaVazioSanitarioEspecificacao_6_1'
        ),
        adotaVazioSanitario_6_1: form.getFieldValue('adotaVazioSanitario_6_1'),
        aguaUtilizadaETratadaComCloro_6_7: form.getFieldValue(
          'aguaUtilizadaETratadaComCloro_6_7'
        ),
        anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11:
          form.getFieldValue(
            'anotacaoDeRTDentroDoPrazoDeValiadeEspecificacao_6_11'
          ),
        anotacaoDeRTDentroDoPrazoDeValiade_6_11: form.getFieldValue(
          'anotacaoDeRTDentroDoPrazoDeValiade_6_11'
        ),
        ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10:
          form.getFieldValue(
            'ausenciaDeArvoresAtrativasDePassarosEspecificacao_5_10'
          ),
        ausenciaDeArvoresAtrativasDePassaros_5_10: form.getFieldValue(
          'ausenciaDeArvoresAtrativasDePassaros_5_10'
        ),
        bebedourosEComedourosDeFacilManejoEspecificacao_5_7: form.getFieldValue(
          'bebedourosEComedourosDeFacilManejoEspecificacao_5_7'
        ),
        bebedourosEComedourosDeFacilManejo_5_7: form.getFieldValue(
          'bebedourosEComedourosDeFacilManejo_5_7'
        ),
        capacidadeTotalAlojamento: form.getFieldValue(
          'capacidadeTotalAlojamento'
        ),
        coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5:
          form.getFieldValue(
            'coberturaDoTelhadoFeitaComMaterialIpermeavelEspecificacao_5_5'
          ),
        coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5: form.getFieldValue(
          'coberturaDoTelhadoFeitaComMaterialIpermeavel_5_5'
        ),
        distanciaAterroSanitarioLixao_1_13: form.getFieldValue(
          'distanciaAterroSanitarioLixao_1_13'
        ),
        distanciaAvesOrnamentais_1_2: form.getFieldValue(
          'distanciaAvesOrnamentais_1_2'
        ),
        distanciaAviarioFabricaRacao_1_11: form.getFieldValue(
          'distanciaAviarioFabricaRacao_1_11'
        ),
        distanciaBiodigestores_1_15: form.getFieldValue(
          'distanciaBiodigestores_1_15'
        ),
        distanciaCriacaoDeAves_1_16: form.getFieldValue(
          'distanciaCriacaoDeAves_1_16'
        ),
        distanciaCriacaoDeAves_1_5: form.getFieldValue(
          'distanciaCriacaoDeAves_1_5'
        ),
        distanciaCriacoesAteMilAves_1_6: form.getFieldValue(
          'distanciaCriacoesAteMilAves_1_6'
        ),
        distanciaDaCercaIsolamento_2_1: form.getFieldValue(
          'distanciaDaCercaIsolamento_2_1'
        ),
        distanciaDaEstradaVicinal_2_5: form.getFieldValue(
          'distanciaDaEstradaVicinal_2_5'
        ),
        distanciaDaFabricaRacaoPropriahan: form.getFieldValue(
          'distanciaDaFabricaRacaoPropria_2_7'
        ),
        distanciaDasResidencias_2_2: form.getFieldValue(
          'distanciaDasResidencias_2_2'
        ),
        distanciaDosLimitesPerifericos_2_6: form.getFieldValue(
          'distanciaDosLimitesPerifericos_2_6'
        ),
        distanciaEntreAvesCorteAvesPoedeiras_1_7: form.getFieldValue(
          'distanciaEntreAvesCorteAvesPoedeiras_1_7'
        ),
        distanciaEntreAvesCortesDiferentesIntegradoras_1_8: form.getFieldValue(
          'distanciaEntreAvesCortesDiferentesIntegradoras_1_8'
        ),
        distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9: form.getFieldValue(
          'distanciaEntreGranjaAvesCorteMesmaIntegradora_1_9'
        ),
        distanciaEntreNucleoAvesCorte_2_4: form.getFieldValue(
          'distanciaEntreNucleoAvesCorte_2_4'
        ),
        distanciaEntreNucleoAvesEOutrasEspecies_2_9: form.getFieldValue(
          'distanciaEntreNucleoAvesEOutrasEspecies_2_9'
        ),
        distanciaEntreNucleoDeAvesESuinos_2_8: form.getFieldValue(
          'distanciaEntreNucleoDeAvesESuinos_2_8'
        ),
        distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3: form.getFieldValue(
          'distanciaEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
        ),
        distanciaEstabelecimentoEnsino_1_3: form.getFieldValue(
          'distanciaEstabelecimentoEnsino_1_3'
        ),
        distanciaGranjaAvesPoedeiras_1_10: form.getFieldValue(
          'distanciaGranjaAvesPoedeiras_1_10'
        ),
        distanciaIncubatorios_1_4: form.getFieldValue(
          'distanciaIncubatorios_1_4'
        ),
        distanciaIndustriaProcessamentoProdutos_1_12: form.getFieldValue(
          'distanciaIndustriaProcessamentoProdutos_1_12'
        ),
        distanciaLagoaResiduo_1_14: form.getFieldValue(
          'distanciaLagoaResiduo_1_14'
        ),
        distanciaMatrizeiros_1_1: form.getFieldValue(
          'distanciaMatrizeiros_1_1'
        ),
        distanciaMinima100mDaEstradaVicinal_2_5: form.getFieldValue(
          'distanciaMinima100mDaEstradaVicinal_2_5'
        ),
        distanciaMinima10kmCriacaoDeAves_1_5: form.getFieldValue(
          'distanciaMinima10kmCriacaoDeAves_1_5'
        ),
        distanciaMinima10kmCriacoesAteMilAves_1_6: form.getFieldValue(
          'distanciaMinima10kmCriacoesAteMilAves_1_6'
        ),
        distanciaMinima1kmBiodigestores_1_15: form.getFieldValue(
          'distanciaMinima1kmBiodigestores_1_15'
        ),
        distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8:
          form.getFieldValue(
            'distanciaMinima1kmEntreAvesCortesDiferentesIntegradoras_1_8'
          ),
        distanciaMinima1kmGranjaAvesPoedeiras_1_10: form.getFieldValue(
          'distanciaMinima1kmGranjaAvesPoedeiras_1_10'
        ),
        distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9:
          form.getFieldValue(
            'distanciaMinima200mEntreGranjaAvesCorteMesmaIntegradora_1_9'
          ),
        distanciaMinima200mEntreNucleoAvesCorte_2_4: form.getFieldValue(
          'distanciaMinima200mEntreNucleoAvesCorte_2_4'
        ),
        distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3:
          form.getFieldValue(
            'distanciaMinima200mEntreNucleosAvesPoedeirasEProducaoOvos_2_3'
          ),
        distanciaMinima20mDasResidencias_2_2: form.getFieldValue(
          'distanciaMinima20mDasResidencias_2_2'
        ),
        distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9:
          form.getFieldValue(
            'distanciaMinima20mEntreNucleoAvesEOutrasEspecies_2_9'
          ),
        distanciaMinima30mDosLimitesPerifericos_2_6: form.getFieldValue(
          'distanciaMinima30mDosLimitesPerifericos_2_6'
        ),
        distanciaMinima3kmAterroSanitarioLixao_1_13: form.getFieldValue(
          'distanciaMinima3kmAterroSanitarioLixao_1_13'
        ),
        distanciaMinima3kmAvesOrnamentais_1_2: form.getFieldValue(
          'distanciaMinima3kmAvesOrnamentais_1_2'
        ),
        distanciaMinima3kmAviarioFabricaRacao_1_11: form.getFieldValue(
          'distanciaMinima3kmAviarioFabricaRacao_1_11'
        ),
        distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7: form.getFieldValue(
          'distanciaMinima3kmEntreAvesCorteAvesPoedeiras_1_7'
        ),
        distanciaMinima3kmEstabelecimentoEnsino_1_3: form.getFieldValue(
          'distanciaMinima3kmEstabelecimentoEnsino_1_3'
        ),
        distanciaMinima3kmIncubatorios_1_4: form.getFieldValue(
          'distanciaMinima3kmIncubatorios_1_4'
        ),
        distanciaMinima3kmIndustriaProcessamentoProdutos_1_12:
          form.getFieldValue(
            'distanciaMinima3kmIndustriaProcessamentoProdutos_1_12'
          ),
        distanciaMinima3kmLagoaResiduo_1_14: form.getFieldValue(
          'distanciaMinima3kmLagoaResiduo_1_14'
        ),
        distanciaMinima3kmMatrizeiros_1_1: form.getFieldValue(
          'distanciaMinima3kmMatrizeiros_1_1'
        ),
        distanciaMinima4kmCriacaoDeAves_1_16: form.getFieldValue(
          'distanciaMinima4kmCriacaoDeAves_1_16'
        ),
        distanciaMinima500mDaFabricaRacaoPropria_2_7: form.getFieldValue(
          'distanciaMinima500mDaFabricaRacaoPropria_2_7'
        ),
        distanciaMinima5mDaCercaIsolamento_2_1: form.getFieldValue(
          'distanciaMinima5mDaCercaIsolamento_2_1'
        ),
        distanciaMinima600mEntreNucleoDeAvesESuinos_2_8: form.getFieldValue(
          'distanciaMinima600mEntreNucleoDeAvesESuinos_2_8'
        ),
        executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3:
          form.getFieldValue(
            'executaProcedimentosParaDesinfeccaoDeVeiculosEspecificacao_4_3'
          ),
        executaProcedimentosParaDesinfeccaoDeVeiculos_4_3: form.getFieldValue(
          'executaProcedimentosParaDesinfeccaoDeVeiculos_4_3'
        ),
        executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3:
          form.getFieldValue(
            'executaProgramaDeLimpezaEDesinfeccaoEspecificacao_6_3'
          ),
        executaProgramaDeLimpezaEDesinfeccao_6_3: form.getFieldValue(
          'executaProgramaDeLimpezaEDesinfeccao_6_3'
        ),
        funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7:
          form.getFieldValue(
            'funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivasEspecificacao_4_7'
          ),
        funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7:
          form.getFieldValue(
            'funcionariosAdentramOEstabelecimentoUtilizandoRoupasExclusivas_4_7'
          ),
        granjaPossuiBarreiraSanitariaEspecificacao_4_5: form.getFieldValue(
          'granjaPossuiBarreiraSanitariaEspecificacao_4_5'
        ),
        granjaPossuiBarreiraSanitaria_4_5: form.getFieldValue(
          'granjaPossuiBarreiraSanitaria_4_5'
        ),
        granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8:
          form.getFieldValue(
            'granjaPossuiFluxoOperacionalEMedidasHigienicoSanitariasEspecificacao_4_8'
          ),
        granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8:
          form.getFieldValue(
            'granjaPossuiFluxoOperacionalEMedidasHigienicoSanitarias_4_8'
          ),
        granjaPossuiSinaisDeAvisoEspecificacao_4_1: form.getFieldValue(
          'granjaPossuiSinaisDeAvisoEspecificacao_4_1'
        ),
        granjaPossuiSinaisDeAviso_4_1: form.getFieldValue(
          'granjaPossuiSinaisDeAviso_4_1'
        ),
        houveNecessidadeDeAvaliacaoDeRisco_7_1: form.getFieldValue(
          'houveNecessidadeDeAvaliacaoDeRisco_7_1'
        ),
        ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6:
          form.getFieldValue(
            'ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridadeEspecificacao_4_6'
          ),
        ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6:
          form.getFieldValue(
            'ingressoOuEgressoDeEquipamentosEMateriaisCumpreMedidaDeBiosseguridade_4_6'
          ),
        instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1:
          form.getFieldValue(
            'instalacoesConstruidasComMateriaisPermitemLimpezaAdequadaEspecificacao_5_1'
          ),
        instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1:
          form.getFieldValue(
            'instalacoesConstruidasComMateriaisPermitemLimpezaAdequada_5_1'
          ),
        malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4:
          form.getFieldValue(
            'malhaDaTelaDosAviariosAteUmaPolegadaEspecificacao_5_4'
          ),
        malhaDaTelaDosAviariosAteUmaPolegada_5_4: form.getFieldValue(
          'malhaDaTelaDosAviariosAteUmaPolegada_5_4'
        ),
        mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9:
          form.getFieldValue(
            'mantemRegistroDasAcoesSanitariasExecutadasEspecificacao_6_9'
          ),
        mantemRegistroDasAcoesSanitariasExecutadas_6_9: form.getFieldValue(
          'mantemRegistroDasAcoesSanitariasExecutadas_6_9'
        ),
        mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8:
          form.getFieldValue(
            'mantemRegistroDasAtividadesDeTransitoDeAvesEspecificacao_6_8'
          ),
        mantemRegistroDasAtividadesDeTransitoDeAves_6_8: form.getFieldValue(
          'mantemRegistroDasAtividadesDeTransitoDeAves_6_8'
        ),
        mantemRegistroDeControleDePragasEspecificacao_6_5: form.getFieldValue(
          'mantemRegistroDeControleDePragasEspecificacao_6_5'
        ),
        mantemRegistroDeControleDePragas_6_5: form.getFieldValue(
          'mantemRegistroDeControleDePragas_6_5'
        ),
        mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10:
          form.getFieldValue(
            'mantemRegistroDeVacinacoesEMedicacoesUtilizadasEspecificacao_6_10'
          ),
        mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10:
          form.getFieldValue(
            'mantemRegistroDeVacinacoesEMedicacoesUtilizadas_6_10'
          ),
        medidasDeBiosseguridadeCompensatorias: form.getFieldValue(
          'medidasDeBiosseguridadeCompensatorias'
        ),
        nomeEstabelecimento: form.getFieldValue('nomeEstabelecimento'),
        muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3:
          form.getFieldValue(
            'muretaDeAlvenariaAdequadoParaLimpezaEspecificacao_5_3'
          ),
        muretaDeAlvenariaAdequadoParaLimpeza_5_3: form.getFieldValue(
          'muretaDeAlvenariaAdequadoParaLimpeza_5_3'
        ),
        necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2:
          form.getFieldValue(
            'necessarioExecucaoDeMedidasDeBiosseguridadeCompensatorias_7_2'
          ),
        estabelecimento: form.getFieldValue('estabelecimento'),
        nomeFiscalizado: form.getFieldValue('nomeFiscalizado'),
        nomeResponsavelAviario: form.getFieldValue('nomeResponsavelAviario'),
        numeroServicoDeInspecao: form.getFieldValue('numeroServicoDeInspecao'),
        observacoesControleAcessoDePessoas: form.getFieldValue(
          'observacoesControleAcessoDePessoas'
        ),
        observacoesDistanciasExternas_1_17: form.getFieldValue(
          'observacoesDistanciasExternas_1_17'
        ),
        observacoesDistanciasInternas: form.getFieldValue(
          'observacoesDistanciasInternas'
        ),
        observacoesInstalacoesEquipamentos_5_12: form.getFieldValue(
          'observacoesInstalacoesEquipamentos_5_12'
        ),
        observacoesMedidasSanitarias_6_12: form.getFieldValue(
          'observacoesMedidasSanitarias_6_12'
        ),
        observacoesTerrenoELocalizacao: form.getFieldValue(
          'observacoesTerrenoELocalizacao'
        ),
        parecer: form.getFieldValue('parecer'),
        pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2:
          form.getFieldValue(
            'pisoFeitoTerraCompactadaOuCimentadoEspecificacao_5_2'
          ),
        pisoFeitoTerraCompactadaOuCimentado_5_2: form.getFieldValue(
          'pisoFeitoTerraCompactadaOuCimentado_5_2'
        ),
        possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6:
          form.getFieldValue(
            'possuemNebulizadoresEOuSistemaDeVentilacaoEspecificacao_5_6'
          ),
        possuemNebulizadoresEOuSistemaDeVentilacao_5_6: form.getFieldValue(
          'possuemNebulizadoresEOuSistemaDeVentilacao_5_6'
        ),
        possuiCercaDeIsolamentoMimino1mEspecificacao_5_8: form.getFieldValue(
          'possuiCercaDeIsolamentoMimino1mEspecificacao_5_8'
        ),
        possuiCercaDeIsolamentoMimino1m_5_8: form.getFieldValue(
          'possuiCercaDeIsolamentoMimino1m_5_8'
        ),
        possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4:
          form.getFieldValue(
            'possuiProtocoloERegistroDeLimpezaEDesinfeccaoEspecificacao_6_4'
          ),
        possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4: form.getFieldValue(
          'possuiProtocoloERegistroDeLimpezaEDesinfeccao_6_4'
        ),
        quantidadeAviarioRecria: form.getFieldValue('quantidadeAviarioRecria'),
        quantidadeAviarioProducao: form.getFieldValue(
          'quantidadeAviarioProducao'
        ),
        quantidadeNucleoProducao: form.getFieldValue(
          'quantidadeNucleoProducao'
        ),
        quantidadeNucleoRecria: form.getFieldValue('quantidadeNucleoRecria'),
        realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6:
          form.getFieldValue(
            'realizaAnualmenteAnaliseMicrobiologicaDaAguaEspecificacao_6_6'
          ),
        realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6: form.getFieldValue(
          'realizaAnualmenteAnaliseMicrobiologicaDaAgua_6_6'
        ),
        realizaControleDeAcessoDePessoasEspecificacao_4_4: form.getFieldValue(
          'realizaControleDeAcessoDePessoasEspecificacao_4_4'
        ),
        realizaControleDeAcessoDePessoas_4_4: form.getFieldValue(
          'realizaControleDeAcessoDePessoas_4_4'
        ),
        realizaControleDeTransitoDeVeiculosEspecificacao_4_2:
          form.getFieldValue(
            'realizaControleDeTransitoDeVeiculosEspecificacao_4_2'
          ),
        realizaControleDeTransitoDeVeiculos_4_2: form.getFieldValue(
          'realizaControleDeTransitoDeVeiculos_4_2'
        ),
        responsavelAviario: form.getFieldValue('responsavelAviario'),
        riscoProporcionadoPelaGranja_7_1_1: form.getFieldValue(
          'riscoProporcionadoPelaGranja_7_1_1'
        ),
        siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9:
          form.getFieldValue(
            'siloDeRacaoFeitoDeMaterialIpermeavelEspecificacao_5_9'
          ),
        siloDeRacaoFeitoDeMaterialIpermeavel_5_9: form.getFieldValue(
          'siloDeRacaoFeitoDeMaterialIpermeavel_5_9'
        ),
        terrenoELocalizacao: form.getFieldValue('terrenoELocalizacao'),
        tipoExploracao: form.getFieldValue('tipoExploracao'),
        tipoServicoDeInspecao: form.getFieldValue('tipoServicoDeInspecao'),
        todosAviariosPertencemAoProprietario: form.getFieldValue(
          'todosAviariosPertencemAoProprietario'
        ),
        todosDocumentosProtocolados: form.getFieldValue(
          'todosDocumentosProtocolados'
        ),
        vinculoComProprietario: form.getFieldValue('vinculoComProprietario'),
        dataParecer: JSON.stringify(
          form.getFieldValue('dataParecer').toDate()
        ).replaceAll('"', ''),
        possuiRequerimentoSolicitacao_8_1: form.getFieldValue(
          'possuiRequerimentoSolicitacao_8_1'
        ),
        requerimentoSolicitacaoDescricao_8_1: form.getFieldValue(
          'requerimentoSolicitacaoDescricao_8_1'
        ),
        possuiCadastroProprietario_8_2: form.getFieldValue(
          'possuiCadastroProprietario_8_2'
        ),
        cadastroProprietarioDescricao_8_2: form.getFieldValue(
          'cadastroProprietarioDescricao_8_2'
        ),
        possuiResponsabilidadeTecnica_8_3: form.getFieldValue(
          'possuiResponsabilidadeTecnica_8_3'
        ),
        responsabilidadeTecnicaDescricao_8_3: form.getFieldValue(
          'responsabilidadeTecnicaDescricao_8_3'
        ),
        possuiPlantaLocalizacaoPropriedade_8_4: form.getFieldValue(
          'possuiPlantaLocalizacaoPropriedade_8_4'
        ),
        plantaLocalizacaoPropriedadeDescricao_8_4: form.getFieldValue(
          'plantaLocalizacaoPropriedadeDescricao_8_4'
        ),
        possuiPlantaBaixaEstabelecimento_8_5: form.getFieldValue(
          'possuiPlantaBaixaEstabelecimento_8_5'
        ),
        plantaBaixaEstabelecimentoDescricao_8_5: form.getFieldValue(
          'plantaBaixaEstabelecimentoDescricao_8_5'
        ),
        possuiMemorialMedidasHigienicoSanitarias_8_6: form.getFieldValue(
          'possuiMemorialMedidasHigienicoSanitarias_8_6'
        ),
        memorialMedidasHigienicoSanitariasDescricao_8_6: form.getFieldValue(
          'memorialMedidasHigienicoSanitariasDescricao_8_6'
        ),
        possuiDocumentoComprobatorioQualidadeAgua_8_7: form.getFieldValue(
          'possuiDocumentoComprobatorioQualidadeAgua_8_7'
        ),
        documentoComprobatorioQualidadeAguaDescricao_8_7: form.getFieldValue(
          'documentoComprobatorioQualidadeAguaDescricao_8_7'
        ),
        assinatura: assinaturaBase64Image,
        statusAssinatura: form.getFieldValue('statusAssinatura'),
        empresaIntegradora: form.getFieldValue('empresaIntegradora'),
      };

      console.log('dto', formLIADTO);

      if (online) {
        if (params.local) {
          saveLIALocal(formLIADTO);
        } else {
          if (shouldSaveOffline) saveLIALocal(formLIADTO);
          else saveLIAOnline(formLIADTO);
        }
      } else {
        saveLIALocal(formLIADTO);
      }
      return false;
    },
    [
      assinaturaBase64Image,
      current,
      form,
      fver,
      online,
      params,
      saveLIAOnline,
      saveLIALocal,
      setLoading,
      shouldSaveOffline,
      stateSteps,
      updateStateSteps,
    ]
  );

  return (
    <>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='LIA'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />

      <Form<FormLIAType>
        form={form}
        layout={'vertical'}
        size={!xs ? 'small' : 'large'}
        autoComplete={'off'}
        initialValues={props.lia}
        onFinish={handleFormSubmit}
        onFinishFailed={() => {
          notification.error({
            message: 'Alguns campos obrigatórios não foram preenchidos',
            description: 'Por favor revise o formulário',
          });
        }}
      >
        {lg && stepsMenu('horizontal', 'vertical')}
        {!lg && (
          <Affix offsetTop={80}>
            <Button
              className='top'
              type='primary'
              onClick={() => {
                showDrawer();
              }}
              block
            >
              Ir para etapa
            </Button>
          </Affix>
        )}

        <Drawer
          title='Etapas'
          placement='right'
          onClose={onClose}
          visible={open}
        >
          {stepsMenu('vertical', 'horizontal')}
        </Drawer>

        <div id='top' className='top' />
        <br />
        <div>
          <fieldset disabled={formDisabled}>
            {steps[current].content ===
              content.INFORMACOES_DA_GRANJA_CADASTRO_EXPLORACAO && (
              <>
                {fver && (
                  <Col xs={24} lg={24} style={{ marginBottom: '24px' }}>
                    <PanelFVER fver={fver} />
                  </Col>
                )}
                <InformacaoGranja form={form} formDisabled={formDisabled} />
              </>
            )}

            {steps[current].content === content.DISTANCIA_EXTERNA && (
              <DistanciasExternas form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.DISTANCIA_INTERNA && (
              <DistanciasInternas form={form} formDisabled={formDisabled} />
            )}
            {steps[current].content === content.TERRENO_LOCALIZACAO && (
              <TerrenoLocalizacao form={form} formDisabled={formDisabled} />
            )}
            {steps[current].content === content.CONTROLE_ACESSO && (
              <ControleAcesso form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.INSTALACOES_EQUIPAMENTOS && (
              <InstalacoesEquipamentos
                form={form}
                formDisabled={formDisabled}
              />
            )}

            {steps[current].content === content.MEDIDAS_SANITARIAS && (
              <MedidasSanitarias form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.RISCO_BIOSEGURIDADE && (
              <RiscoBioseguridade form={form} formDisabled={formDisabled} />
            )}
            {steps[current].content === content.DOCUMENTOS && (
              <Documentos form={form} formDisabled={formDisabled} />
            )}

            {steps[current].content === content.PARECER_FINAL && (
              <ParecerFinal form={form} formDisabled={formDisabled} />
            )}
          </fieldset>
        </div>

        <Divider />

        <Row justify={'end'} gutter={24}>
          {!params.local &&
            online &&
            mode === 'CREATE' &&
            current === steps.length - 1 &&
            !formDisabled && (
              <Col xs={24}>
                <Row justify={'end'}>
                  <Col>
                    <Form.Item>
                      <Switch
                        checked={shouldSaveOffline}
                        onChange={setShouldSaveOffline}
                      />
                      <span> Salvar offline</span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

          {current > 0 && (
            <Col xs={12}>
              <Button
                style={{ width: '100%', height: '40px' }}
                onClick={() => {
                  handleStepChange(current - 1);
                  window.location.replace('#');
                }}
              >
                Anterior
              </Button>
            </Col>
          )}
          {current < steps.length - 1 && (
            <Col xs={12}>
              <Button
                type='primary'
                style={{ width: '100%', height: '40px' }}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      updateStateSteps(current);
                      handleStepChange(current + 1);
                      window.location.replace('#');
                    })
                    .catch(() => {
                      notification.error({
                        message:
                          'Alguns campos obrigatórios não foram preenchidos',
                        description: 'Por favor revise o formulário',
                      });
                    });
                }}
              >
                Próximo
              </Button>
            </Col>
          )}
          {current === steps.length - 1 && !formDisabled && (
            <Col xs={12}>
              <Button
                type='primary'
                htmlType={'submit'}
                block
                style={{ height: '40px' }}
              >
                Salvar
              </Button>
            </Col>
          )}
          {formDisabled && current === steps.length - 1 && (
            <Col xs={12} lg={12}>
              <Button
                style={{ width: '100%', height: '40px' }}
                htmlType={'submit'}
                type={'primary'}
                onClick={() => {
                  navigate(`/lia/edicao/${params.id}`);
                }}
              >
                Editar
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <CustomModal
        open={fverNaoEncontrado}
        closable={true}
        maskClosable={false}
        keyboard={false}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        bodyStyle={{ padding: 0 }}
        footer={null}
        title='FVER não encontrado'
        width={'100%'}
        onCancel={() => navigate('/acoes-de-campo')}
      >
        <div
          style={{
            marginTop: '15px',
            marginLeft: '25px',
            marginRight: '25px',
          }}
        >
          <Typography.Paragraph style={{ textAlign: 'justify' }}>
            Este Laudo de Inspeção não possui nenhum FVER vinculado. Por causa
            disso, será necessário selecionar um novo FVER.
          </Typography.Paragraph>

          <FilterPanelFVER open />

          <br />
          <Table<FVER.Summary>
            rowKey={'id'}
            dataSource={fvers?.content}
            size={'small'}
            loading={fetching}
            pagination={{
              current: query.page ? query.page + 1 : 1,
              onChange: (page, pageSize) => {
                dispatch(
                  FVERActions.setQuery({ page: page - 1, size: pageSize })
                );
              },
              total: fvers?.totalElements,
              pageSize: query.size,
            }}
            columns={[
              {
                dataIndex: 'numero',
                title: 'Número',
                width: 175,
                responsive: ['xs', 'sm'],
                render(numero) {
                  return <span style={{ fontWeight: 'bold' }}>{numero}</span>;
                },
              },
              {
                dataIndex: 'dataDaVisita',
                title: 'Data',
                width: 100,
                responsive: ['xs', 'sm'],
                render(data: FVER.Summary['dataDaVisita']) {
                  return moment(new Date(data)).format('DD/MM/YYYY');
                },
              },
              {
                dataIndex: 'tipoEstabelecimento',
                title: 'Tipo',
                width: 150,
                responsive: ['md'],
                render(tipo: FVER.Summary['tipoEstabelecimento']) {
                  return (
                    <Tag>{TipoEstabelecimento.valueOf(tipo).toString()}</Tag>
                  );
                },
              },
              {
                dataIndex: 'nomeEstabelecimento',
                title: 'Nome',
                responsive: ['sm'],
                render(nome: FVER.Summary['nomeEstabelecimento']) {
                  return <Tooltip title={nome}>{nome}</Tooltip>;
                },
              },
              {
                dataIndex: 'id',
                title: '',
                responsive: ['xs', 'sm'],
                width: 130,
                align: 'right',
                render(idVisita: number, fverSelected) {
                  return (
                    <Space>
                      <Button
                        icon={<SelectOutlined style={{ color: 'green' }} />}
                        size={'small'}
                        type={'ghost'}
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Deseja selecionar o FVER nº{' '}
                                {fverSelected.numero}
                              </>
                            ),
                            content: (
                              <Typography.Paragraph>
                                Esta ação não pode ser desfeita.
                              </Typography.Paragraph>
                            ),
                            onOk: async () => {
                              await fetchFVERById(fverSelected.id)
                                .then((fver) => {
                                  form.setFieldValue('fver', fver);
                                  setFVERNaoEncontrado(false);
                                })
                                .catch((e) => {
                                  notification.error({
                                    message: 'Erro ao buscar o FVER',
                                  });
                                });
                            },
                          });
                        }}
                      />
                    </Space>
                  );
                },
              },
            ]}
          ></Table>
        </div>
        <br />
      </CustomModal>
    </>
  );
}
