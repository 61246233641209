import pt_BR from 'antd/lib/locale/pt_BR';
import './index.less';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as ServiceWorkerControllerActions from './core/store/ServiceWorkerController.slice';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CustomRoutes from './app/CustomRoutes';
import { store } from './core/store';
import DefaultLayout from './app/layouts/Default/Default.layout';
import { SW_INIT, SW_UPDATE } from './core/store/types';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom';
import './core/auth/httpConfig';
import ScrollToTop from './app/components/ScrollToTop';

import 'moment/locale/pt-br';
import * as momentTZ from 'moment-timezone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

moment.locale('pt-br');
momentTZ.tz.setDefault('Etc/UTC');

/* Execute on start app */
/* window.addEventListener('load', async () => {
  window.alert('ABRIU');
}); */

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <ConfigProvider locale={pt_BR}>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop />
            <DefaultLayout>
              <CustomRoutes />
            </DefaultLayout>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onSuccess: () =>
    store.dispatch(
      ServiceWorkerControllerActions.rootReducer({
        payload: null,
        type: SW_INIT,
      })
    ),
  onUpdate: (reg) =>
    store.dispatch(
      ServiceWorkerControllerActions.rootReducer({
        payload: reg,
        type: SW_UPDATE,
      })
    ),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
