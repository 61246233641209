import localforage from 'localforage';
import { useCallback, useState } from 'react';
import { FVER } from '../../sdk/@types';
import { SYNC_VISITA_NEEDED } from '../../sdk/@types/ServiceWorker.types';
import FVERIDBService from '../../sdk/services/indexeddb/FVERIDB.service';
import FVERService from '../../sdk/services/SIZ-API/FVER.service';
import AuthorizationService from '../auth/Authorization.service';
import generateHash from '../functions/generateHash';
import {
  ServiceIDBPayload,
  ServiceIDBPayloadInput,
} from '../../sdk/services/indexeddb/ServiceIDB';
import FVVIDBService from '../../sdk/services/indexeddb/FVVIDB.service';
import LIAIDBService from '../../sdk/services/indexeddb/LIAIDB.service';
import useNavigatorStatus from './useNavigatorStatus';

export default function useFVER() {
  const { online } = useNavigatorStatus();
  const [fver, setFVER] = useState<FVER.Input | undefined>();
  const [fvers, setFVERs] = useState<FVER.Paginated>();
  const [visitas2, setVisitas2] = useState<FVER.Summary[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [query, setQuery] = useState<FVER.Query>({
    page: 0,
    size: 10,
  });

  const fetchFVERs = useCallback(() => {
    return FVERService.getAll(query).then(setFVERs);
  }, [query]);

  const fetchFVERById = useCallback(async (id: number, local?: boolean) => {
    if (!local) return await FVERService.getById(id).then(setFVER);
    else {
      return await FVERIDBService.getByIdFromInput_Table(id).then((v) => {
        setFVER(v);
      });
    }
  }, []);

  const fetchFVERByCodigoVerificador = useCallback(
    async (codigoVerificador: string, local?: boolean) => {
      if (!local)
        return await FVERService.getByCodigoVerificador_PublicAccess(
          codigoVerificador
        ).then((fver) => {
          setFVER(fver);
          return fver;
        });
      else {
        return await FVERIDBService.getByCodigoVerificador_InputTable(
          codigoVerificador
        ).then((fver) => {
          setFVER(fver);
          return fver;
        });
      }
    },
    []
  );

  const modifyQuery = useCallback(
    (modifiedQuery: FVER.Query) => {
      setQuery({
        ...query,
        ...modifiedQuery,
      });
    },
    [query]
  );

  const fetchByNumero = useCallback(
    async (numero: string, fromApi?: boolean) => {
      try {
        setFetching(true);

        if (!fromApi) {
          return await FVERIDBService.getByNumero(numero).then(
            async (payload) => {
              if (payload) {
                setFVER(payload?.payload);
              } else if (online) {
                return await FVERService.getByNumero(numero).then(setFVER);
              } else setFVER(undefined);
            }
          );
        } else {
          return await FVERService.getByNumero(numero).then(setFVER);
        }
      } catch (err) {
        throw err;
      } finally {
        setFetching(false);
      }
    },
    [online]
  );

  const fetchByMunicipioEData = useCallback(
    (codgIBGE: string, dataInicio: string, dataFim: string) => {
      return FVERService.getByMunicipio(codgIBGE, dataInicio, dataFim).then(
        setVisitas2
      );
    },
    []
  );

  const insert = useCallback(
    (fverInput: FVER.Input) => {
      fverInput.codigoVerificador = generateHash(fverInput);
      fverInput.status = 'NOVO';

      if (online) {
        return FVERService.add(fverInput);
      } else {
        if (navigator.serviceWorker)
          navigator.serviceWorker.ready.then((swRegistration) => {
            //@ts-ignore
            if (swRegistration.sync) {
              localforage.setItem(
                'token',
                AuthorizationService.getAccessToken()
              );
              navigator.serviceWorker.ready.then((registration) => {
                //@ts-ignore
                registration.sync.register(SYNC_VISITA_NEEDED);
              });
            } else {
            }
          });
        return FVERIDBService.insert(fverInput);
      }
    },
    [online]
  );

  const updateFVEROffline = useCallback(
    async (id: number, visita: FVER.Input) => {
      const oldCodigoVerificador = visita.codigoVerificador;
      const newCodigoVerificador = generateHash(visita);
      let newFVER: ServiceIDBPayloadInput = {
        id: id,
        payload: {
          ...visita,
          codigoVerificador: newCodigoVerificador,
        },
        status: 'NOVO',
        date: new Date(),
      };

      return await FVERIDBService.update(id, newFVER).then(async (idVisita) => {
        const updateFVV = async () => {
          return await FVVIDBService.getByCodigoVerificador(
            oldCodigoVerificador
          ).then(async (fvv) => {
            if (fvv) {
              let newFVV: ServiceIDBPayload = {
                ...fvv,
                payload: {
                  ...fvv.payload,
                  visitaPropriedadeRural: {
                    ...visita,
                    codigoVerificador: newCodigoVerificador,
                    id: -1,
                  },
                },
              };

              return await FVVIDBService.update(
                //@ts-ignore
                fvv.id,
                newFVV
              );
            }
          });
        };

        const updateLIA = async () => {
          return await LIAIDBService.getByCodigoVerificador(
            oldCodigoVerificador
          ).then(async (lia) => {
            if (lia) {
              let newLIA: ServiceIDBPayload = {
                ...lia,
                payload: {
                  ...lia.payload,
                  fver: {
                    ...visita,
                    codigoVerificador: newCodigoVerificador,
                    id: -1,
                  },
                },
              };

              return await LIAIDBService.update(
                //@ts-ignore
                lia.id,
                newLIA
              );
            }
          });
        };

        return Promise.all([updateFVV(), updateLIA()]);
      });
    },
    []
  );

  return {
    fetchByMunicipioEData,
    fetchByNumero,
    fetchFVERByCodigoVerificador,
    fetchFVERById,
    fetchFVERs,
    fetching,
    insert,
    query,
    modifyQuery,
    setQuery,
    updateFVEROffline,
    fver,
    fvers,
    visitas2,
  };
}
