import {
  Button,
  Carousel,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  RadioChangeEvent,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  DatabaseOutlined,
  FileExcelOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { FVER } from '../../../sdk/@types';
import { useNavigate } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import { TipoEstabelecimento } from '../../../core/enums/TipoEstabelecimento';
import FilterPanelFVER from './FilterPanelFVER';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import * as FVERActions from '../../../core/store/FVER.slice';
import { CustomModal } from '../../components/CustomModal';
import FVERService from '../../../sdk/services/SIZ-API/FVER.service';
import { StatusDocumento } from '../../../core/enums/StatusDocumento';
import AnexosFVER from './AnexosFVER';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useDevOptions from '../../../core/hooks/useDevOptions';
import PermissionsService from '../../../core/auth/Permissions.service';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { FVERFormulariosAnexos } from '../../../sdk/@types/FVERFormulariosAnexos';
import FormulariosAnexosFVER from './FormulariosAnexosFVER';

enum TipoAcaoCancelamento {
  NOVO,
  COPIAR,
  VINCULAR,
}

export default function ListFVER() {
  const { online } = useNavigatorStatus();
  const { xs, sm } = useBreakpoint();
  const { idInTables } = useDevOptions();
  const dispatch = useDispatch();
  const { setLoading, setFirstOpening } = useLoadingPage();
  const navigate = useNavigate();
  const slider = useRef(null);

  const [dadosModalFormulariosAnexosFVER, setDadosModalFormulariosAnexosFVER] =
    useState<{
      fver: FVER.Summary;
      formulariosAnexos: FVERFormulariosAnexos.Summary;
    }>();

  const [error, setError] = useState<Error>();
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalFormsVisible, setModalFormsVisible] = useState(false);
  const [fverSummary, setFVERSummary] = useState<FVER.Summary>();
  const [cancelPanelTab, setCancelPanelTab] = useState(0);

  const [acaoCancelamento, setAcaoCancelamento] =
    useState<TipoAcaoCancelamento>(TipoAcaoCancelamento.NOVO);

  const fvers = useSelector((state: RootState) => state.fver.fvers);
  const query = useSelector((state: RootState) => state.fver.query);
  const fetching = useSelector((state: RootState) => state.fver.fetching);

  //control expandedKeys
  const [expandedKey, setExpandedKey] = useState<any>([]);

  const onExpand = (_: any, fver: FVER.Summary) => {
    setExpandedKey((prev: any) => {
      const newKey = fver.id;
      if (prev !== newKey) {
        return newKey;
      }
      return null;
    });
  };
  //end control expandedKeys

  if (!online)
    setError(
      new Error('Não é possível visualizar a lista de FVER no modo offline')
    );

  if (error) throw error;

  useEffect(() => {
    setFirstOpening(true);
  }, [setFirstOpening]);

  useEffect(() => {}, [cancelPanelTab]);

  useEffect(() => {}, [fvers]);

  const onChange = (e: RadioChangeEvent) => {
    setAcaoCancelamento(e.target.value);
  };

  const handleFormCancelamentoSubmit = useCallback(
    async (formValues) => {
      if (fverSummary) {
        return await FVERService.cancel(fverSummary?.id, formValues.motivo);
      }
    },
    [fverSummary]
  );

  const afterChange = useCallback((index) => {
    setCancelPanelTab(index);
  }, []);

  return (
    <>
      <FilterPanelFVER />
      <br />
      <Row>
        <Col span={24}>
          {fetching && <Skeleton active />}

          {!fetching && (
            <>
              <Table<FVER.Summary>
                rowKey={'id'}
                dataSource={fvers?.content}
                size={'small'}
                loading={fetching}
                pagination={{
                  current: query.page ? query.page + 1 : 1,
                  onChange: (page, pageSize) => {
                    dispatch(
                      FVERActions.setQuery({
                        ...query,
                        page: page - 1,
                        size: pageSize,
                      })
                    );
                  },
                  total: fvers?.totalElements,
                  pageSize: query.size,
                }}
                title={() => {
                  return (
                    <Row justify={'space-between'}>
                      <Typography.Title level={5} style={{ color: 'white' }}>
                        FVERs
                      </Typography.Title>

                      <Space>
                        <Col>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => navigate('/visitas/cadastro')}
                            type={'primary'}
                            title={'Novo'}
                          />
                        </Col>

                        <Col>
                          <Button
                            icon={<SyncOutlined />}
                            type={'primary'}
                            title={'Atualizar'}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                dispatch(FVERActions.filter(query));
                              } finally {
                                setLoading(false);
                              }
                            }}
                          />
                        </Col>
                      </Space>
                    </Row>
                  );
                }}
                columns={[
                  {
                    dataIndex: 'id',
                    width: idInTables ? 100 : 0,
                    render(id) {
                      return (
                        idInTables && (
                          <Typography.Text>{`ID: ${id}`}</Typography.Text>
                        )
                      );
                    },
                  },
                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    title: 'Detalhes',
                    render(_, fver) {
                      return (
                        <Space direction='vertical' size={0}>
                          <Space direction='horizontal' size={8} wrap={xs}>
                            <Typography.Text strong>
                              {fver.numero}
                            </Typography.Text>

                            {fver.status ===
                              StatusDocumento.keyOf(
                                StatusDocumento.CANCELADO
                              ) && (
                              <Tag
                                style={{
                                  backgroundColor: `${
                                    fver.status ===
                                    StatusDocumento.keyOf(
                                      StatusDocumento.VALIDO
                                    )
                                      ? '#86d886'
                                      : fver.status ===
                                        StatusDocumento.keyOf(
                                          StatusDocumento.CANCELADO
                                        )
                                      ? '#e37878'
                                      : '#d0d0d0d1'
                                  }`,
                                  color: 'white',
                                }}
                              >
                                {StatusDocumento.valueOf(
                                  fver.status
                                ).toString()}
                              </Tag>
                            )}
                          </Space>

                          <Space
                            direction='horizontal'
                            size={24}
                            style={{ fontSize: '0.8em' }}
                            wrap={sm || xs}
                          >
                            <Typography.Text>
                              {moment(new Date(fver.dataDaVisita)).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </Typography.Text>

                            <Tag>
                              {TipoEstabelecimento.valueOf(
                                fver.tipoEstabelecimento
                              ).toString()}
                            </Tag>
                            <Typography>
                              {`${fver.codigoEstabelecimento} - ${fver.nomeEstabelecimento}`}
                            </Typography>
                          </Space>
                        </Space>
                      );
                    },
                  },

                  {
                    dataIndex: 'id',
                    responsive: ['xs', 'sm'],
                    width: 85,
                    align: 'right',
                    render(id: number, fver: FVER.Summary) {
                      return (
                        <Space>
                          {fver.status !== 'CANCELADO' &&
                            PermissionsService.visitaPropriedadeRural_remover && (
                              <Button
                                danger
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                  setModalCancelVisible(true);
                                  setFVERSummary(fver);
                                }}
                                size={'small'}
                                type={'ghost'}
                              />
                            )}

                          {PermissionsService.dev && (
                            <Button
                              icon={<DatabaseOutlined />}
                              onClick={async () => {
                                await FVERService.getFormulariosAssociados(
                                  id
                                ).then((formulariosAnexos) => {
                                  setDadosModalFormulariosAnexosFVER({
                                    fver,
                                    formulariosAnexos,
                                  });

                                  setModalFormsVisible(true);
                                });
                              }}
                              size={'small'}
                              type={'ghost'}
                            />
                          )}

                          <Button
                            icon={<PrinterOutlined />}
                            onClick={() =>
                              navigate(`/visitas/visualizar/${id}`)
                            }
                            size={'small'}
                            type={'ghost'}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
                expandable={{
                  columnWidth: '50px',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <MinusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ) : (
                      <PlusCircleTwoTone
                        onClick={(e) => onExpand(record, e)}
                        style={{ fontSize: '24px' }}
                      />
                    ),
                  expandRowByClick: true,
                  expandedRowKeys: [expandedKey],
                  expandedRowRender: (fver) => (
                    <AnexosFVER
                      tipoEstabelecimento={fver.tipoEstabelecimento}
                      idFVER={fver.id}
                      idFVV={fver.fvv?.id}
                      idLIA={fver.laudoIFSEAC?.id}
                      local={false}
                      status={fver.status}
                    />
                  ),
                  onExpand: onExpand,
                  expandedRowClassName: () => 'expanded-row-siz',
                }}
                showHeader={false}
              />
            </>
          )}
        </Col>
      </Row>

      <CustomModal
        centered
        closable={false}
        destroyOnClose
        footer={null}
        keyboard={true}
        maskClosable={true}
        onCancel={() => {
          setModalCancelVisible(false);
          setFVERSummary(undefined);
        }}
        open={modalCancelVisible}
        title={'Cancelar Documento'}
      >
        <Form
          autoComplete={'off'}
          layout='vertical'
          onFinish={async (formValues) => {
            /* if (!fverSummary?.fvv) {
              await handleFormCancelamentoSubmit(formValues).then((fver) => {
                notification.success({
                  message: `O FVER nº ${fverSummary?.numero} foi cancelado`,
                });
                setModalCancelVisible(false);
                dispatch(FVERActions.filter(query));
              });
            } else {
              //@ts-ignore
              slider.current.next();
            } */
            await handleFormCancelamentoSubmit(formValues).then((fver) => {
              notification.success({
                message: `O FVER nº ${fverSummary?.numero} foi cancelado`,
              });
              setModalCancelVisible(false);
              dispatch(FVERActions.filter(query));
            });
          }}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          preserve={false}
          size='small'
        >
          <Carousel
            afterChange={afterChange}
            dots={false}
            ref={slider}
            infinite={false}
            style={{ width: '100%' }}
          >
            <div style={{ width: '100%', padding: '250px' }}>
              <Descriptions
                column={2}
                labelStyle={{ fontWeight: 'bold' }}
                size='small'
              >
                <Descriptions.Item label='Número' span={1}>
                  {fverSummary?.numero}
                </Descriptions.Item>
                <Descriptions.Item label='Data' span={1}>
                  {moment(fverSummary?.dataDaVisita).format('DD/MM/YYYY HH:mm')}
                </Descriptions.Item>
                <Descriptions.Item label='Estabelecimento' span={2}>
                  {fverSummary?.nomeEstabelecimento}
                </Descriptions.Item>
              </Descriptions>

              <Form.Item
                label='Motivo'
                name='motivo'
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Input.TextArea maxLength={500} rows={3} showCount />
              </Form.Item>

              {/* {fverSummary?.fvv && (
                <>
                  <Alert
                    description={
                      'O FVV nº 5105200-0003/2023 está vinculado a este FVER. Não será possível cancelar o documento a menos que um novo FVER seja informado no FVV'
                    }
                    type='warning'
                  />
                  <br />
                  <Col span={24}>
                    <Button
                      htmlType='submit'
                      style={{ width: '100%' }}
                      type='primary'
                    >
                      Continuar
                    </Button>
                  </Col>
                </>
              )} */}

              {/* {!fverSummary?.fvv && ( */}
              {
                <>
                  <Row gutter={24}>
                    <Divider />
                    <Col span={12}>
                      <Button
                        onClick={() => {
                          setModalCancelVisible(false);
                          setFVERSummary(undefined);
                        }}
                        style={{ width: '100%' }}
                        type='ghost'
                      >
                        Fechar
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        htmlType={'submit'}
                        style={{ width: '100%' }}
                        type='primary'
                      >
                        Cancelar FVER
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            </div>
            {/* <div style={{ width: '100%' }}>
              <Form.Item
                label={'Escolha uma ação'}
                name={'acao'}
                rules={[
                  {
                    required: cancelPanelTab === 1,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Radio.Group onChange={onChange} value={acaoCancelamento}>
                  <Space direction='vertical'>
                    <Radio value={TipoAcaoCancelamento.NOVO}>
                      Criar novo FVER em branco
                    </Radio>
                    <Radio value={TipoAcaoCancelamento.COPIAR}>
                      Criar novo FVER a partir desse
                    </Radio>
                    <Radio value={TipoAcaoCancelamento.VINCULAR}>
                      Vincular um FVER já existente
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <div style={{ position: 'absolute', bottom: 0, width: '50%' }}>
                <Divider />
                <Row gutter={24}>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        //@ts-ignore
                        slider.current.prev();
                      }}
                      style={{ width: '100%' }}
                      type='ghost'
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button style={{ width: '100%' }} type='primary'>
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </div>
            </div> */}
          </Carousel>
        </Form>
      </CustomModal>

      <CustomModal
        centered
        closable={false}
        destroyOnClose
        footer={
          <Button
            onClick={() => {
              setModalFormsVisible(false);
              setFVERSummary(undefined);
            }}
            style={{ width: '100%' }}
            type='ghost'
          >
            Voltar
          </Button>
        }
        keyboard={true}
        maskClosable={true}
        onCancel={() => {
          setModalFormsVisible(false);
          setDadosModalFormulariosAnexosFVER(undefined);
        }}
        open={modalFormsVisible}
        style={{ maxWidth: '400px' }}
        title={'Formulários Anexos'}
      >
        {dadosModalFormulariosAnexosFVER && (
          <FormulariosAnexosFVER
            formulariosAnexos={
              dadosModalFormulariosAnexosFVER?.formulariosAnexos
            }
            local={false}
            fver={dadosModalFormulariosAnexosFVER.fver}
          />
        )}
      </CustomModal>
    </>
  );
}
