import {
  BookOutlined,
  BugOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CoffeeOutlined,
  DesktopOutlined,
  DisconnectOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  ImportOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MonitorOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  SyncOutlined,
  TeamOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Divider,
  Image,
  Layout,
  Menu,
  notification,
  Row,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import AuthorizationService from '../../../core/auth/Authorization.service';
import useBadgeState from '../../../core/hooks/useBadgeState';
import useOuterClick from '../../../core/hooks/useOuterClick';
import { CustomModal } from '../../components/CustomModal';
import PermissionsService from '../../../core/auth/Permissions.service';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import getRandomInteger from '../../../core/functions/getRandomInteger';
const { SubMenu } = Menu;
const { Sider } = Layout;

export default function DefaultLayoutSider() {
  const { online } = useNavigatorStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const { xs, sm, lg } = useBreakpoint();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { quantidadeAcoesNaoSincronizadas } = useBadgeState();

  const [showModalInstallationVisible, setShowModalInstallationVisible] =
    useState<boolean>(false);

  const [showModalAtualizacao, setShowModalAtualizacao] =
    useState<boolean>(false);
  const [alreadyInstalled, setAlreadyInstalled] = useState(false);

  const [promptEvent, setPromptEvent] = useState<any>();

  const [randomNumber, setRandomNumber] = useState<number>(
    getRandomInteger(1000000000, 9999999999)
  );

  const accessToken = AuthorizationService.getAccessToken();

  useEffect(() => {}, [accessToken]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', function (e) {
      e.preventDefault();
      setPromptEvent(e);
      setShowModalInstallationVisible(true);
    });
  }, []);

  useEffect(() => {
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;

    //@ts-ignore
    if (navigator.standalone || isStandalone) {
      setAlreadyInstalled(true);
    } else setAlreadyInstalled(false);
  }, [setAlreadyInstalled]);

  useEffect(() => {}, [alreadyInstalled]);

  useEffect(() => {
    if (lg) setCollapsed(false);
    if (!lg) setCollapsed(true);
  }, [lg]);

  useEffect(() => {}, [promptEvent]);

  const handleOuterClick = () => {
    if (lg) return;
    if (!collapsed) setCollapsed(true);
  };

  const innerRef = useOuterClick((ev: any) => {
    handleOuterClick();
  });

  const trigger = () => {
    return (
      <div className='ant-layout-sider-trigger-custom'>
        {collapsed && (
          <MenuUnfoldOutlined
            style={{ fontSize: '32px', color: 'white' }}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}

        {!collapsed && (
          <MenuFoldOutlined
            style={{ fontSize: '32px', color: 'white' }}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {AuthorizationService.isAuthenticated() && (
        <div ref={innerRef} className='sider-wrapper'>
          {trigger()}

          <Sider
            width={250}
            collapsible
            collapsed={collapsed}
            collapsedWidth={`${xs || sm ? '0' : '80px'}`}
            defaultCollapsed={false}
            breakpoint={'lg'}
            trigger={null}
            style={{
              overflowY: 'initial',
              overflowX: 'hidden',
              height: '100%',
              position: 'sticky',
              zIndex: '1',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Menu
              mode='inline'
              selectedKeys={[location.pathname]}
              defaultOpenKeys={
                collapsed ? [] : [location.pathname.split('/')[1], 'devs']
              }
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item
                key='/home'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  navigate('/home');
                }}
                icon={<HomeOutlined />}
              >
                Home
              </Menu.Item>

              <Menu.Item
                key='/educacaosanitaria'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  //@ts-ignore
                  window.open('https://linktr.ee/educaindea', '_blank').focus();
                }}
                icon={<BookOutlined />}
              >
                Educaçao Sanitária
              </Menu.Item>

              <Menu.Item
                key='/gestaodemetas'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  //@ts-ignore
                  window
                    .open(
                      `http://cdsa:${encodeURIComponent(
                        '$4Iei1b7#SXc'
                      )}@pbi.mti.mt.gov.br/Reports/browse/INDEA/ANIMAL?xyz=${randomNumber}`,
                      '_blank'
                    )
                    .focus();
                  setRandomNumber(getRandomInteger(1000000000, 9999999999));
                }}
                icon={<LineChartOutlined />}
              >
                Gestão de metas
              </Menu.Item>

              <Menu.Item
                key='/manuais'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  //@ts-ignore
                  window
                    .open(
                      'https://drive.google.com/drive/folders/1jPEOh9qcs6QePYPtgGHbnesgyFYzzUxT?usp=sharing',
                      '_blank'
                    )
                    .focus();
                }}
                icon={<ReadOutlined />}
              >
                Manuais técnicos
              </Menu.Item>

              <SubMenu
                key='formularios'
                icon={<ReconciliationOutlined />}
                title='Formulários'
              >
                <Menu.Item
                  key='/visitas'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/visitas');
                  }}
                >
                  FVER
                </Menu.Item>

                <Menu.Item
                  key='/vigilancias'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/vigilancias');
                  }}
                >
                  FVV
                </Menu.Item>

                <Menu.Item
                  key='/lia'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/lia');
                  }}
                >
                  Laudo de Inspeção
                </Menu.Item>

                <Menu.Item
                  key='/tfr'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/tfr');
                  }}
                >
                  TFR
                </Menu.Item>
              </SubMenu>

              <Menu.Item
                key='/acoes-de-campo'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  navigate('/acoes-de-campo');
                }}
                icon={<CloudUploadOutlined />}
              >
                <Badge
                  count={quantidadeAcoesNaoSincronizadas}
                  overflowCount={99}
                  size='small'
                  showZero={false}
                  title={`Você possui ${quantidadeAcoesNaoSincronizadas} ações ainda não sincronizadas`}
                >
                  Ações de campo
                </Badge>
              </Menu.Item>

              <Menu.Item
                key='/offline'
                onClick={() => {
                  if (!lg) setCollapsed(true);
                  navigate('/offline');
                }}
                icon={<DisconnectOutlined />}
              >
                Sincronizar Dados
              </Menu.Item>

              <Divider style={{ margin: 0, marginTop: -5, padding: 0 }} />

              {PermissionsService.admin && (
                <SubMenu
                  key='admin'
                  icon={<ClusterOutlined />}
                  title='Administração'
                >
                  <Menu.Item
                    key='/usuarios'
                    onClick={() => {
                      if (!lg) setCollapsed(true);
                      navigate('/usuarios');
                    }}
                    icon={<TeamOutlined />}
                  >
                    Usuários
                  </Menu.Item>
                </SubMenu>
              )}
              <SubMenu
                key='config'
                icon={<SettingOutlined />}
                title={'Configurações'}
              >
                <Menu.Item
                  key='/instalacao'
                  onClick={() => {
                    setShowModalInstallationVisible(true);
                  }}
                  icon={<VerticalAlignBottomOutlined />}
                >
                  Instalar
                </Menu.Item>

                <Menu.Item
                  key='/atualizacao'
                  onClick={() => {
                    setShowModalAtualizacao(true);
                  }}
                  icon={<SyncOutlined />}
                >
                  Atualizar
                </Menu.Item>
              </SubMenu>

              {(PermissionsService.dev || PermissionsService.tester) && (
                <SubMenu
                  key='devs'
                  icon={<CoffeeOutlined />}
                  style={{ backgroundColor: '#8ec1fca6' }}
                  title='Devs'
                >
                  {PermissionsService.dev && (
                    <Menu.Item
                      key='/environment'
                      onClick={() => {
                        if (!lg) setCollapsed(true);
                        navigate('/environment');
                      }}
                      icon={<DesktopOutlined />}
                    >
                      Ambiente
                    </Menu.Item>
                  )}

                  {(PermissionsService.dev || PermissionsService.tester) && (
                    <Menu.Item
                      key='/gps'
                      onClick={() => {
                        if (!lg) setCollapsed(true);
                        navigate('/gps');
                      }}
                      icon={<EnvironmentOutlined />}
                    >
                      Teste GPS
                    </Menu.Item>
                  )}

                  {PermissionsService.dev && (
                    <Menu.Item
                      key='/atendimentosErrosSincronizacao'
                      onClick={() => {
                        if (!lg) setCollapsed(true);
                        navigate('/atendimentosErrosSincronizacao');
                      }}
                      icon={<BugOutlined />}
                    >
                      Erros de Sincronização
                    </Menu.Item>
                  )}

                  {PermissionsService.dev && (
                    <Menu.Item
                      key='/importJSON'
                      onClick={() => {
                        if (!lg) setCollapsed(true);
                        navigate('/importJSON');
                      }}
                      icon={<ImportOutlined />}
                    >
                      Importar JSON
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              <SubMenu
                key='relatorios'
                icon={<MonitorOutlined />}
                title='Relatórios'
              >
                <Menu.Item
                  key='/relatorioFVER'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/relatorioFVER');
                  }}
                >
                  FVER
                </Menu.Item>

                <Menu.Item
                  key='/relatorioFVV'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/relatorioFVV');
                  }}
                >
                  FVV - Simplificado
                </Menu.Item>

                {/* <Menu.Item
                  key='/historico'
                  onClick={() => {
                    if (!lg) setCollapsed(true);
                    navigate('/historico');
                  }}
                >
                  Histórico Estabelecimento
                </Menu.Item> */}
              </SubMenu>

              <Menu.Item
                key='/logout'
                onClick={() => {
                  AuthorizationService.logout();
                }}
                icon={<LogoutOutlined />}
              >
                Sair
              </Menu.Item>
            </Menu>

            <CustomModal
              centered
              open={showModalInstallationVisible}
              footer={
                <>
                  <Row
                    gutter={24}
                    hidden={alreadyInstalled || !promptEvent}
                    justify='space-between'
                  >
                    <Col span={12}>
                      <Button
                        style={{ width: '100%' }}
                        onClick={() => {
                          setShowModalInstallationVisible(false);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>

                    <Col span={12}>
                      <Button
                        id='buttonInstallationPWA'
                        style={{ width: '100%' }}
                        type={'primary'}
                        onClick={() => {
                          setShowModalInstallationVisible(false);

                          if (!promptEvent) {
                            return;
                          }
                          promptEvent.prompt();
                          promptEvent.userChoice.then((choice: string) => {
                            if (choice === 'accepted') {
                              setPromptEvent(null);
                              notification.success({
                                message: 'SIZ instalado com sucesso',
                              });
                            }
                          });
                        }}
                      >
                        Instalar
                      </Button>
                    </Col>
                  </Row>

                  <Row gutter={24} hidden={!alreadyInstalled && promptEvent}>
                    <Col xs={24}>
                      <Button
                        style={{ width: '100%' }}
                        type={'primary'}
                        onClick={() => {
                          setShowModalInstallationVisible(false);
                        }}
                      >
                        Fechar
                      </Button>
                    </Col>
                  </Row>
                </>
              }
              closable={false}
              maskClosable={false}
              width={xs ? '100%' : '300px'}
              title={'Instalar SIZ'}
            >
              <Typography.Paragraph>{alreadyInstalled}</Typography.Paragraph>

              <Row gutter={24} justify='start'>
                <Col xs={6}>
                  <Image height={'48px'} src={'/SIZ-128x128.png'} />
                </Col>

                <Col xs={18}>
                  <Typography.Paragraph>Indea-MT</Typography.Paragraph>
                </Col>
              </Row>

              <Row hidden={alreadyInstalled || !promptEvent}>
                <Typography.Paragraph style={{ textAlign: 'justify' }}>
                  O SIZ mobile pode ser instalado como um app no seu dispositivo
                  para que seja acessado com maior agilidade e comodidade no
                  futuro.
                </Typography.Paragraph>

                <Typography.Text style={{ textAlign: 'justify' }}>
                  Deseja continuar com a instalação?
                </Typography.Text>
              </Row>
              <Row hidden={!alreadyInstalled && promptEvent}>
                <Col xs={24}>
                  <Typography.Paragraph style={{ textAlign: 'justify' }}>
                    O SIZ mobile já está instalado neste dispositivo ou o seu
                    dispositivo não possui suporte a esta função.
                  </Typography.Paragraph>
                </Col>
              </Row>
            </CustomModal>

            <CustomModal
              centered
              open={showModalAtualizacao}
              footer={
                <Row justify='center'>
                  <Col span={24}>
                    <Button
                      style={{ width: '100%' }}
                      type={'primary'}
                      onClick={() => {
                        if ('serviceWorker' in navigator) {
                          navigator.serviceWorker
                            .getRegistration()
                            .then((registration) =>
                              registration?.waiting?.postMessage({
                                type: 'SKIP_WAITING',
                              })
                            );
                        }
                        window.location.reload();
                      }}
                    >
                      Atualizar
                    </Button>
                  </Col>
                </Row>
              }
              closable={true}
              maskClosable={true}
              width={xs ? '100%' : '450px'}
              onCancel={() => setShowModalAtualizacao(false)}
              title={'Atualizar'}
            >
              <Row gutter={24} justify='start' align='bottom'>
                <Col xs={24}>
                  <Typography.Paragraph style={{ textAlign: 'justify' }}>
                    O SIZ Mobile é capaz de se atualizar automaticamente. Essa
                    atualização não é feita instantaneamente para não prejudicar
                    usuários ativos.
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ textAlign: 'justify' }}>
                    Porém, caso você deseje, pode forçar a atualização no seu
                    dispositivo clicando no botão abaixo
                  </Typography.Paragraph>
                </Col>
              </Row>
            </CustomModal>
          </Sider>
        </div>
      )}
    </>
  );
}
