import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import * as SyncingStateActions from '../store/SyncingState.slice';

export default function useSyncingState() {
  const dispatch = useDispatch();

  const fver = useSelector((state: RootState) => state.syncing.fver);
  const fvv = useSelector((state: RootState) => state.syncing.fvv);
  const formVin = useSelector((state: RootState) => state.syncing.formVin);
  const tfr = useSelector((state: RootState) => state.syncing.tfr);
  const lia = useSelector((state: RootState) => state.syncing.lia);

  const setFver = useCallback(
    (newState: boolean) => {
      dispatch(SyncingStateActions.setFver(newState));
    },
    [dispatch]
  );

  const setFvv = useCallback(
    (newState: boolean) => {
      dispatch(SyncingStateActions.setFvv(newState));
    },
    [dispatch]
  );

  const setFormVin = useCallback(
    (newState: boolean) => {
      dispatch(SyncingStateActions.setFormVin(newState));
    },
    [dispatch]
  );

  const setTfr = useCallback(
    (newState: boolean) => {
      dispatch(SyncingStateActions.setTfr(newState));
    },
    [dispatch]
  );
  const setLIA = useCallback(
    (newState: boolean) => {
      dispatch(SyncingStateActions.setLIA(newState));
    },
    [dispatch]
  );
  return {
    fver,
    fvv,
    tfr,
    lia,
    formVin,
    setFver,
    setFvv,
    setFormVin,
    setTfr,
    setLIA,
  };
}
