import { Button, Col, Row, Typography } from 'antd';
import { CustomModal } from './CustomModal';
import { useNavigate } from 'react-router';
import AnexosFVER from '../features/FVER/AnexosFVER';
import { useCallback } from 'react';
import { FVER } from '../../sdk/@types';

export type FormDataModalFinalizacao = {
  id?: number;
  numero?: string;
  local?: boolean;
  networkError?: boolean;
  tipoEstabelecimento?: FVER.Summary['tipoEstabelecimento'];
};

interface ModalFinalizacaoCadastroProps {
  formData: FormDataModalFinalizacao;
  open: boolean;
  setOpen: (open: boolean) => void;
  formName: 'FVER' | 'FVV' | 'LIA' | 'TFR';
}

export default function ModalFinalizacaoCadastro(
  props: ModalFinalizacaoCadastroProps
) {
  const navigate = useNavigate();

  const to = useCallback((): string => {
    if (props.formData.local) return '/acoes-de-campo';
    if (props.formName === 'LIA') return '/lia';
    else if (props.formName === 'FVER') return '/visitas';
    else if (props.formName === 'FVV') return '/vigilancias';
    else if (props.formName === 'TFR') return '/tfr';
    else return '/';
  }, [props]);

  return (
    <CustomModal
      centered
      closable={false}
      destroyOnClose
      footer={
        <Row
          align='middle'
          style={{
            justifyContent: 'center',
          }}
          gutter={12}
        >
          <Col span={24}>
            <Button
              onClick={() => navigate(to())}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      }
      open={props.open}
      style={{ maxWidth: '350px' }}
      title={`${props.formName} salvo com sucesso`}
    >
      <Row
        gutter={24}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {props.formData.networkError && (
          <Col xs={24} sm={24}>
            <Typography.Paragraph>
              Houve um problema enquanto tentamos nos comunicar com o servidor.
            </Typography.Paragraph>
          </Col>
        )}

        {props.formData.local && (
          <Col xs={24} sm={24}>
            <Typography.Paragraph>
              {`O ${props.formName} foi salvo localmente no dispositivo`}
            </Typography.Paragraph>
            <Typography.Paragraph>
              Quando estiver conectado à internet, vá até a página{' '}
              <span style={{ fontWeight: 'bold' }}>Ações de campo</span> para
              sincronizá-lo
            </Typography.Paragraph>
          </Col>
        )}
        {!props.formData.local && (
          <>
            <Col xs={24} sm={24}>
              <Typography.Paragraph>
                O {props.formName} nº{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {props.formData.numero
                    ? props.formData.numero
                    : props.formData.id}
                </span>{' '}
                foi salvo no servidor e não necessita sincronização
              </Typography.Paragraph>
            </Col>
          </>
        )}

        {props.formName === 'FVER' && (
          <Col span={24}>
            <AnexosFVER
              idFVER={props.formData.id!}
              local={props.formData.local ? props.formData.local : false}
              tipoEstabelecimento={props.formData.tipoEstabelecimento!}
              /* 
              @TODO trocar parâmetro para canCreate: boolean
              status={form.getFieldsValue().status} 
              */
            />
          </Col>
        )}
      </Row>
    </CustomModal>
  );
}
