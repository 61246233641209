import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SyncingState {
  fver: boolean;
  fvv: boolean;
  formVin: boolean;
  tfr: boolean;
  lia: boolean;
}

const initialState: SyncingState = {
  fver: false,
  fvv: false,
  formVin: false,
  tfr: false,
  lia: false,
};

const SyncingStateSlice = createSlice({
  initialState,
  name: 'syncingState',
  reducers: {
    setFver(state, action: PayloadAction<boolean>) {
      state.fver = action.payload;
    },
    setFvv(state, action: PayloadAction<boolean>) {
      state.fvv = action.payload;
    },
    setFormVin(state, action: PayloadAction<boolean>) {
      state.formVin = action.payload;
    },
    setTfr(state, action: PayloadAction<boolean>) {
      state.tfr = action.payload;
    },
    setLIA(state, action: PayloadAction<boolean>) {
      state.lia = action.payload;
    },
  },
});

export const { setFver, setFvv, setFormVin, setTfr, setLIA } =
  SyncingStateSlice.actions;

const SyncingStateReducer = SyncingStateSlice.reducer;
export default SyncingStateReducer;
