import tutorial_1 from '../../assets/gps_locker/1.jpg';
import tutorial_2 from '../../assets/gps_locker/2.jpg';
import tutorial_3 from '../../assets/gps_locker/3.jpg';
import tutorial_4 from '../../assets/gps_locker/4.jpg';
import tutorial_5 from '../../assets/gps_locker/5.jpg';
import tutorial_6 from '../../assets/gps_locker/6.jpg';
import tutorial_7 from '../../assets/gps_locker/7.jpg';
import tutorial_8 from '../../assets/gps_locker/8.jpg';

import { Carousel } from 'react-responsive-carousel';
import {
  InfoCircleOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Image,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import NumberFormat from 'react-number-format';
import { ButtonGoogleMaps } from './ButtonGoogleMaps';
import { CustomModal } from './CustomModal';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import CircleFilled from '../Icons/CircleFilled';
import useWindowDimensions from '../../core/hooks/useWindowDimensions';

interface CoordenadasPanelProps {
  form: FormInstance;
  formDisabled: boolean;
  pathToCoordenada: string[];
  onAcceptCoords: (coords: GeolocationCoordinates) => any;
}

export default function CoordenadasPanel(props: CoordenadasPanelProps) {
  const [fetching, setFetching] = useState(false);
  const [showModalOrientacoes, setShowModalOrientacoes] = useState(false);
  const [showModalTutorialGPSLocker, setShowModalTutorialGPSLocker] =
    useState(false);

  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 100,
      timeout: 60000,
    },
    suppressLocationOnMount: true,
    userDecisionTimeout: 5000,
    watchPosition: false,
    onError: (error) => console.log('useGeolocated.getPosition.onError', error),
    onSuccess: (position) =>
      console.log('useGeolocated.getPosition.onSuccess', position),
  });

  const images = [
    {
      image: tutorial_1,
      label: (
        <span>
          No primeiro acesso ao GPS Locker, será solicitado que você conceda
          permissão para que o app use o GPS. Conceda a permissão clicando em{' '}
          <b>GIVE ACCESS</b>.
        </span>
      ),
    },
    {
      image: tutorial_2,
      label: (
        <span>
          Conceda a permissão clicando em <b>DURANTE O USO DO APP</b>.
        </span>
      ),
    },
    {
      image: tutorial_3,
      label: (
        <span>
          O indicador na cor <b style={{ color: '#DC202E' }}>VERMELHA</b>{' '}
          significa que o GPS Locker está inativo e ainda não obteve a
          localização do dispositivo.
        </span>
      ),
    },
    {
      image: tutorial_4,
      label: (
        <span>
          Para obter a localização do dispositivo, clique em <b>LOCK GPS</b>.
        </span>
      ),
    },
    {
      image: tutorial_5,
      label: (
        <span>
          Enquanto o GPS Locker obtém a localização, o indicador estará na cor{' '}
          <b style={{ color: '#FFCA0A' }}>AMARELA</b>.
        </span>
      ),
    },
    {
      image: tutorial_6,
      label: (
        <span>
          Após obter a localização do dispositivo, o indicador estará{' '}
          <b style={{ color: '#00B58C' }}>VERDE</b>. Desse momento em diante o
          GPS Locker continuará capturando a localização do dispositivo ao longo
          do tempo, até que seja desligado.
        </span>
      ),
    },
    {
      image: tutorial_7,
      label: (
        <span>
          Para desligar o GPS Locker você pode fechar o aplicativo ou clicar em{' '}
          <b>UNLOCK GPS</b>.
        </span>
      ),
    },
    {
      color: 'black',
      image: tutorial_8,
      label: (
        <span>
          Depois de ativar o GPS Locker você pode voltar para o SIZ e continuar
          a trabalhar normalmente. Este indicador na barra de notificações
          indica que o GPS Locker está ativo no momento.
        </span>
      ),
    },
  ];

  const hookUseGeolocationUpdateCoords = useCallback(() => {
    if (isGeolocationAvailable) {
      setFetching(true);
      getPosition();
    } else {
      Modal.error({
        title: 'Não foi possível obter a localização',
        content: (
          <>
            <Typography.Paragraph style={{ textAlign: 'justify' }}>
              Este dispositivo não é capaz de obter a sua localização.
            </Typography.Paragraph>
          </>
        ),
      });
    }
  }, [getPosition, isGeolocationAvailable]);

  useEffect(() => {
    if (coords && !positionError) {
      notification.success({
        message: 'Sucesso',
      });

      setFetching(false);
    } else if (!coords && positionError) {
      notification.error({
        message: 'Erro',
      });

      setFetching(false);
    }
  }, [coords, positionError]);

  /* 
  const onClickButtonUpdateCoords = () => {
    if ('geolocation' in navigator) {
      getPosition();

      if (coords) {
        props.onAcceptCoords(coords);
        message.success('Coordenadas atualizadas');
      } else {
        let message;
        if (positionError?.code === 1) {
          if (positionError.message.includes('secure')) {
            message =
              'A conexão do SIZ não é mais segura, contate o administrador do sistema';
          } else {
            message =
              'O SIZ não possui permissão para acessar a sua localização';
          }
        } else if (positionError?.code === 2) {
          message =
            'O seu dispositivo não capacidade de localização ou ela está desativada';
        } else {
          message = 'O seu dispositivo está demorando para responder';
        }

        notification.error({
          message: 'Não foi possível obter a localização',
          description: message,
        });
      }
    } else {
      Modal.error({
        title: 'Não foi possível obter a localização',
        content: (
          <>
            <Typography.Paragraph style={{ textAlign: 'justify' }}>
              Este dispositivo não é capaz de obter a sua localização.
            </Typography.Paragraph>
          </>
        ),
      });
    }
  }; 
  */

  const { width } = useWindowDimensions();

  return (
    <>
      <Spin
        size='large'
        spinning={fetching}
        style={{
          position: 'fixed',
          top: '20%',
          zIndex: '1',
        }}
      >
        {!isGeolocationAvailable && (
          <Alert
            style={{ marginBottom: '5px' }}
            message={'Este dispositivo não possui suporte à geolocalização'}
            type='error'
            showIcon
          />
        )}

        {!isGeolocationEnabled && (
          <Alert
            style={{ marginBottom: '5px' }}
            message={'O SIZ não possui permissão para usar a geolocalização'}
            type='warning'
            showIcon
          />
        )}
        <Row align={'bottom'} gutter={24}>
          <Col span={24}>
            <Typography.Title level={5}>
              <>
                Coordenadas Geográficas{' '}
                <InfoCircleOutlined
                  onClick={() => setShowModalOrientacoes(true)}
                />
              </>
            </Typography.Title>
          </Col>
          <Col xs={24} md={12}>
            <Card size='small' title='Latitude' bordered={false}>
              <Row gutter={12}>
                <Col xs={8} lg={8}>
                  <Form.Item
                    hidden
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'id',
                    ]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <Form.Item
                    hidden
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'pessoa',
                      'id',
                    ]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <Form.Item
                    hidden
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'orientacaoLatitude',
                    ]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <Form.Item
                    hidden
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'orientacaoLongitude',
                    ]}
                  >
                    <Input hidden />
                  </Form.Item>

                  <Form.Item
                    label='Grau'
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'latGrau',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <Input
                      maxLength={2}
                      inputMode='numeric'
                      autoComplete='off'
                      disabled
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} lg={8}>
                  <Form.Item
                    label={'Minuto'}
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'latMin',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <Input
                      maxLength={2}
                      inputMode='numeric'
                      autoComplete='off'
                      disabled
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} lg={8}>
                  <Form.Item
                    label={'Segundo'}
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'latSeg',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <NumberFormat
                      className='ant-col ant-form-item-control ant-form-item-control-input ant-input '
                      style={{ maxWidth: '100%', height: '24px' }}
                      value={12345}
                      displayType='input'
                      type={'text'}
                      decimalSeparator=','
                      decimalScale={1}
                      max={180}
                      maxLength={4}
                      min={-180}
                      autoComplete={'off'}
                      disabled
                      onKeyPress={(event: any) => {
                        let { value } = event.target;

                        if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                          event.preventDefault();
                        }

                        if (value) {
                          value = value.replace(/(\d{2})/, '$1,');
                          value = value.replace(',,', ',');
                        }

                        event.currentTarget.value = value;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card size='small' title='Longitude' bordered={false}>
              <Row gutter={12}>
                <Col xs={8} lg={8}>
                  <Form.Item
                    label={'Grau'}
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'longGrau',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <Input
                      maxLength={3}
                      inputMode='numeric'
                      autoComplete='off'
                      disabled
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} lg={8}>
                  <Form.Item
                    label={'Minuto'}
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'longMin',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <Input
                      maxLength={3}
                      inputMode='numeric'
                      autoComplete='off'
                      disabled
                      onKeyPress={(event: any) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} lg={8}>
                  <Form.Item
                    label={'Segundo'}
                    name={[
                      ...props.pathToCoordenada,
                      'coordenadaGeografica',
                      'longSeg',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                    ]}
                  >
                    <NumberFormat
                      className='ant-col ant-form-item-control ant-form-item-control-input ant-input '
                      style={{ maxWidth: '100%', height: '24px' }}
                      value={12345}
                      displayType='input'
                      type={'text'}
                      decimalSeparator=','
                      decimalScale={1}
                      max={180}
                      maxLength={4}
                      min={-180}
                      autoComplete={'off'}
                      disabled
                      onKeyPress={(event: any) => {
                        let { value } = event.target;

                        if (!/[0-9]/.test(event.key) || !/./.test(event.key)) {
                          event.preventDefault();
                        }

                        if (value) {
                          value = value.replace(/(\d{2})/, '$1,');
                          value = value.replace(',,', ',');
                        }

                        event.currentTarget.value = value;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />
        <Row>
          <Col xs={24} lg={8}>
            <Space direction='horizontal' size={'small'}>
              <Button
                icon={<SyncOutlined />}
                style={{ width: '100%' }}
                type={'primary'}
                onClick={hookUseGeolocationUpdateCoords}
                disabled={props.formDisabled}
              >
                Atualizar Coordenadas
              </Button>

              <ButtonGoogleMaps
                coordenadas={props.form.getFieldValue([
                  'propriedade',
                  'coordenadaGeografica',
                ])}
                disabled={props.formDisabled}
              />
            </Space>
          </Col>
        </Row>
      </Spin>

      <CustomModal
        closable={false}
        footer={
          <Row justify='end'>
            <Col span={12}>
              <Button
                onClick={() => setShowModalOrientacoes(false)}
                style={{ width: '100%' }}
                type={'primary'}
              >
                Ok
              </Button>
            </Col>
          </Row>
        }
        maskClosable={true}
        keyboard={true}
        open={showModalOrientacoes}
        title='Orientações'
      >
        <>
          <Typography.Paragraph style={{ textAlign: 'justify' }}>
            Para melhorar a rapidez e precisão na obtenção das coordenadas
            geográficas no modo offline, recomendamos utilizar junto com o SIZ o
            aplicativo GPS Locker.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Button
              onClick={() => {
                //@ts-ignore
                window
                  .open(
                    'https://play.google.com/store/apps/details?id=com.silentlexx.gpslock',
                    '_blank'
                  )
                  .focus();
              }}
              style={{
                color: 'blue',
                paddingLeft: '0',
                paddingRight: '0',
              }}
              type='text'
            >
              Clique aqui
            </Button>{' '}
            para para fazer o download do aplicativo.
          </Typography.Paragraph>

          <Typography.Paragraph>
            <Button
              onClick={() => {
                setShowModalOrientacoes(false);
                setShowModalTutorialGPSLocker(true);
              }}
              style={{
                color: 'blue',
                paddingLeft: '0',
                paddingRight: '0',
              }}
              type='text'
            >
              Clique aqui
            </Button>{' '}
            para orientações sobre o uso do GPS Locker.
          </Typography.Paragraph>
        </>
      </CustomModal>

      <CustomModal
        closable={false}
        footer={null}
        fullscreen={true}
        keyboard={true}
        maskClosable={true}
        open={showModalTutorialGPSLocker}
        style={{}}
        bodyStyle={{ padding: '5px 5px' }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        title={null}
      >
        <Carousel
          className='carouselelel'
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <button
                  className='nav_btn nav_btn_right'
                  onClick={clickHandler}
                >
                  <RightCircleTwoTone
                    twoToneColor={'#337ab7'}
                    style={{ fontSize: '2rem' }}
                  />
                </button>
              )
            );
          }}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              hasPrev && (
                <button className='nav_btn nav_btn_left' onClick={clickHandler}>
                  <LeftCircleTwoTone
                    twoToneColor={'#337ab7'}
                    style={{ fontSize: '2rem' }}
                  />
                </button>
              )
            );
          }}
          renderIndicator={(clickHandler, isSelected, index) => {
            return (
              <li
                onClick={clickHandler}
                key={index}
                role='button'
                style={{
                  display: 'inline-block',
                  margin: '4px',
                }}
              >
                <CircleFilled size={isSelected ? '24px' : undefined} />
              </li>
            );
          }}
          renderItem={(item: React.ReactNode) => {
            return item;
          }}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          labels={{
            leftArrow: 'teste',
            rightArrow: 'teste',
            item: 'teste',
          }}
        >
          {images.map((image, index) => (
            <div style={{}}>
              <Image
                src={image.image}
                alt={`tutorial ${index}`}
                key={index}
                preview={false}
                style={{
                  height: 'auto',
                  maxWidth: Number(width) - 50,
                }}
              />
              <div
                style={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  top: '550px',
                  color: 'white',
                  position: 'absolute',
                  width: '100%',
                }}
              >
                <Typography.Paragraph
                  style={{
                    color: image.color ? image.color : 'white',
                    margin: '10px 10px',
                  }}
                >
                  {image.label}
                </Typography.Paragraph>
              </div>
            </div>
          ))}
        </Carousel>
        <Row
          justify='end'
          style={{ backgroundColor: '#fff', padding: '10px 5px' }}
        >
          <Col span={12}>
            <Button
              onClick={() => setShowModalTutorialGPSLocker(false)}
              style={{ width: '100%' }}
              type={'primary'}
            >
              Fechar
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
}
