import { Col, Row, Space } from 'antd';
import ButtonToForm from '../../components/ButtonToForm';
import { useCallback } from 'react';
import { FVER } from '../../../sdk/@types';

interface AnexosFVERProps {
  tipoEstabelecimento: FVER.Summary['tipoEstabelecimento'];
  idFVER: number;
  idFVV?: number;
  idLIA?: number;
  local: boolean;
  status?: 'ALTERADO' | 'CANCELADO' | 'NOVO' | 'VALIDO';
  statusLocal?: 'ERRO' | 'NAO FINALIZADO' | 'NOVO' | 'SINCRONIZADO';
}

export default function AnexosFVER(props: AnexosFVERProps) {
  const fverPossuiTodosOsAnexos = useCallback(() => {
    return props.idFVV && props.idLIA;
  }, [props]);

  const fverNaoPossuiNenhumAnexo = useCallback(() => {
    return !props.idFVV && !props.idLIA;
  }, [props]);

  const linkNewFVV = `/vigilancias/cadastro/${props.idFVER}${
    props.local ? '/local' : ''
  }`;
  const linkEditFVV = `/vigilancias/edicao/${props.idFVV}${
    props.local ? '/local' : ''
  }`;
  const linkNewLIA = `/lia/cadastro/${props.idFVER}${
    props.local ? '/local' : ''
  }`;
  const linkEditLIA = `/lia/edicao/${props.idLIA}${
    props.local ? '/local' : ''
  }`;

  return (
    <Row gutter={24}>
      <Col xs={24} md={12}>
        <Space direction='vertical' size={5}>
          <span style={{ fontStyle: 'italic' }}>Anexos desta visita</span>
          <Space
            size={1}
            direction='horizontal'
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '0.7em',
            }}
            wrap
          >
            {fverNaoPossuiNenhumAnexo() && '-'}

            {props.idFVV && (
              <ButtonToForm label='FVV' route={linkEditFVV} type='VIEW' />
            )}

            {props.idLIA && (
              <ButtonToForm
                disabled={props.status === 'CANCELADO'}
                label='LIA'
                route={linkEditLIA}
                type='VIEW'
              />
            )}
          </Space>
        </Space>
      </Col>

      <Col xs={24} md={12}>
        <Space direction='vertical' size={5}>
          {props.status !== 'CANCELADO' &&
            props.tipoEstabelecimento === 'PROPRIEDADE' && (
              <span style={{ fontStyle: 'italic' }}>Inserir Anexo</span>
            )}
          {props.status === 'CANCELADO' && (
            <span style={{ color: 'red' }}>
              Não é possível incluir anexo em um FVER cancelado
            </span>
          )}

          {props.status !== 'CANCELADO' &&
            props.tipoEstabelecimento !== 'PROPRIEDADE' && (
              <span style={{ color: 'red' }}>
                Não é possível incluir anexo em um FVER de Abatedouro ou Recinto
              </span>
            )}
          <Space
            size={1}
            direction='horizontal'
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '0.7em',
            }}
            wrap
          >
            {fverPossuiTodosOsAnexos() && '-'}

            {!props.idFVV && (
              <ButtonToForm
                disabled={
                  props.status === 'CANCELADO' ||
                  props.tipoEstabelecimento !== 'PROPRIEDADE'
                }
                label='FVV'
                route={linkNewFVV}
                type='INCLUDE'
              />
            )}

            {!props.idLIA && (
              <ButtonToForm
                disabled={
                  props.status === 'CANCELADO' ||
                  props.tipoEstabelecimento !== 'PROPRIEDADE'
                }
                label='LIA'
                route={linkNewLIA}
                type='INCLUDE'
              />
            )}
          </Space>
        </Space>
      </Col>
    </Row>
  );
}
