/* eslint-disable no-lone-blocks */
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import TextArea from 'antd/lib/input/TextArea';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActive } from '../../../core/store/TipoChavePrincipalTFR.slice';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLoadingPage from '../../../core/hooks/useLoadingPage';
import useAssinaturaBase64Image from '../../../core/hooks/useAssinaturaBase64Image';
import {
  TipoChaveSecundariaTFR,
  Revenda,
  TermoFiscalizacao,
  ChavePrincipalTFR,
  ChaveSecundariaTFR,
  TFRServidor,
} from '../../../sdk/@types';

import { CustomModal } from '../../components/CustomModal';
import scrollFieldToTop from '../../../core/functions/scrollFieldToTop';

import { RootState } from '../../../core/store';
import * as TFRActions from '../../../core/store/TFR.slice';

import TFRIDBService from '../../../sdk/services/indexeddb/TFRIDB.service';
import RevendaPanel from '../../components/RevendaPanel';

import SignatureWrapper from '../../components/SignatureWrapper';
import { StatusAssinatura } from '../../../core/enums/StatusAssinatura';
import TFRServidoresPanel from '../../components/TFRServidoresPanel';
import AuthorizationService from '../../../core/auth/Authorization.service';
import ServidorIDBService from '../../../sdk/services/indexeddb/ServidorIDB.service';
import { usePrompt } from '../../../core/hooks/usePrompt';
import ModalFinalizacaoCadastro, {
  FormDataModalFinalizacao,
} from '../../components/ModalFinalizacaoCadastro';
import useNavigatorStatus from '../../../core/hooks/useNavigatorStatus';
import { StaticTimePicker } from '@mui/x-date-pickers';

type FormTFRType = {
  dataVisita?: Moment;
  horaDaVisita?: string;
  revenda: Revenda.Summary;
  modalTipoChavePrimaria?: {
    id: number;
    nome: string;
  };
  modalListaTipoChaveSecundaria?: TipoChaveSecundariaTFR.Summary[];
  servidores?: TFRServidor.Summary;
} & Omit<TermoFiscalizacao.Request, 'dataVisita'>;

type FormTFRProps = {
  tfr?: FormTFRType;
};

export default function FormTFR(props: FormTFRProps) {
  const dispatch = useDispatch();
  const { online } = useNavigatorStatus();

  const [statusAssinaturaState, setStatusAssinaturaState] = useState();

  const [form] = Form.useForm<FormTFRType>();
  const [shouldSaveOffline, setShouldSaveOffline] = useState<boolean>(false);

  const params = useParams<{ id: string; local?: string }>();
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const { setLoading, mode } = useLoadingPage();
  const [dataSourceTableChavesPrimarias, setDatasourceTableChavesPrimarias] =
    useState<FormTFRType['chavesPrincipaisTermoFiscalizacao']>([]);

  const [showModalTimePicker, setShowModalTimePicker] =
    useState<boolean>(false);
  const [showDropdownChaveSecundaria, setShowDropdownChaveSecundaria] =
    useState<boolean>(false);
  const [showModalProgramas, setShowModalProgramas] = useState<boolean>(false);
  const [chavesSecundarias, setChavesSecundarias] = useState<any[]>([]);
  const [showModalFinalizacao, setShowModalFinalizacao] =
    useState<boolean>(false);

  usePrompt(!showModalFinalizacao);

  const { assinaturaBase64Image, setAssinaturaBase64Image } =
    useAssinaturaBase64Image();
  const [listaServidores, setListaServidores] = useState<TFRServidor.Summary[]>(
    []
  );
  const listaStatusAssinatura = StatusAssinatura.keys();
  const [dadosModalFinalizacao, setDadosModalFinalizacao] =
    useState<FormDataModalFinalizacao>({});

  const loading = useSelector((state: RootState) => state.revenda.loading);
  const tfrs = useSelector(
    (state: RootState) => state.tfr.tipoChavePrincipalTFR
  );
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const cleanModalProgramasEAtividades = useCallback(() => {
    form.setFieldsValue({
      modalTipoChavePrimaria: undefined,
      modalListaTipoChaveSecundaria: undefined,
    });
  }, [form]);

  const saveTFRLocal = useCallback(
    async (tfr: TermoFiscalizacao.Request, problema_rede?: boolean) => {
      if (params.id) {
        try {
          dispatch(TFRActions.updateTFROffline({ id: Number(params.id), tfr }));
          setDadosModalFinalizacao({
            id: Number(params.id),
            numero: '',
            local: true,
          });

          setShowModalFinalizacao(true);
        } catch (e: any) {
          notification.error({
            message: 'Não foi possível atualizar o TFR no dispositivo',
            description: e.message,
          });
        }
      } else {
        try {
          await TFRIDBService.insert(tfr).then((idTFR) => {
            setDadosModalFinalizacao({
              id: Number(idTFR.toString()),
              local: true,
            });
            setShowModalFinalizacao(true);
          });
        } catch (e: any) {
          notification.error({
            message: 'Não foi possível salvar o TFR no dispositivo',
            description: e.message,
          });
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [params, setLoading, dispatch]
  );

  const saveTFROnline = useCallback(
    async (tfr: TermoFiscalizacao.Request) => {
      try {
        const action = await dispatch(
          //@ts-ignore
          tfr.id
            ? TFRActions.updateTFR({
                id: Number(params.id),
                tfr: tfr,
              })
            : TFRActions.insertTFR(tfr)
        );
        //@ts-ignore
        if (action.payload) {
          //@ts-ignore
          const fiscalizacao = action.payload;

          setDadosModalFinalizacao({
            id: Number(fiscalizacao.id),
            numero: fiscalizacao.numero,
          });

          setShowModalFinalizacao(true);
        }
      } catch (e: any) {
        // ...
      } finally {
        setLoading(false);
      }
    },
    [setLoading, dispatch, params]
  );

  useEffect(() => {
    if (props.tfr?.servidores) setListaServidores(props.tfr.servidores);
  }, [props.tfr, params, setLoading]);

  useEffect(() => {
    const insertLoggedUser = async () => {
      if (mode === 'CREATE') {
        if (AuthorizationService.getUsuarioSIZ()) {
          await ServidorIDBService.getByCpf(
            //@ts-ignore
            AuthorizationService.getUsuarioSIZ()?.cpf.replace(/\D/g, '')
          ).then((servidor) => {
            if (servidor) {
              const newLista = listaServidores.concat({
                servidor: servidor.payload,
              });
              setListaServidores(newLista);
            }
          });
        }
      }
    };

    insertLoggedUser();
  }, []);

  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);

    if (mode !== 'CREATE')
      setStatusAssinaturaState(form.getFieldValue('statusAssinatura'));
  }, [mode, form]);
  useEffect(() => {
    setFormDisabled(mode === 'VIEW' ? true : false);

    if (mode !== 'CREATE')
      setStatusAssinaturaState(form.getFieldValue('statusAssinatura'));
  }, [mode, form]);

  useEffect(() => {
    if (form) setAssinaturaBase64Image(form.getFieldValue('assinatura'));
  }, [form]);

  useEffect(() => {
    if (props.tfr?.assinatura) setAssinaturaBase64Image(props.tfr.assinatura);
  }, [setAssinaturaBase64Image]);

  useEffect(() => {
    form.setFieldsValue({ servidores: listaServidores });
  }, [listaServidores, form]);

  const temVeterinarioNaLista = useCallback(() => {
    return listaServidores.some(
      (servidor) => servidor.servidor?.profissao?.nome === 'MÉDICO VETERINÁRIO'
    );
  }, [listaServidores]);

  const handleFormSubmit = useCallback(
    async (formParam: FormTFRType) => {
      if (!temVeterinarioNaLista()) {
        notification.info({
          message: 'Erro na validação',
          description: 'Pelo menos um dos servidores deve ser Veterinário.',
        });
        return false;
      }
      setLoading(true);
      const lista: TermoFiscalizacao.Request['chavesPrincipaisTermoFiscalizacao'] =
        dataSourceTableChavesPrimarias?.map((chave) => {
          return {
            tipoChavePrincipalTermoFiscalizacao: {
              id: chave.tipoChavePrincipalTermoFiscalizacao?.id,
              nome: chave.tipoChavePrincipalTermoFiscalizacao?.nome,
              tiposChavesSecundariasTermoFiscalizacao:
                chave.tipoChavePrincipalTermoFiscalizacao?.tiposChavesSecundariasTermoFiscalizacao?.map(
                  (sec) => ({
                    id: Number(sec.id),
                    nome: sec.nome,
                  })
                ),
            },
            chavesSecundariasTermoFiscalizacao:
              chave.chavesSecundariasTermoFiscalizacao?.map((sec) => ({
                tipoChaveSecundariaTermoFiscalizacao: {
                  id: Number(sec.tipoChaveSecundariaTermoFiscalizacao?.id),
                  nome: sec.tipoChaveSecundariaTermoFiscalizacao?.nome,
                },
              })),
          };
        });

      const data: Moment = form.getFieldValue('dataVisita');
      const hora: string = form.getFieldValue('horaDaVisita');

      let dataMoment = data.set({
        hour: Number(hora.split(':')[0]),
        minute: Number(hora.split(':')[1]),
      });

      const formTFRDTO: TermoFiscalizacao.Request = {
        ...formParam,
        chavesPrincipaisTermoFiscalizacao: lista,
        dataVisita: dataMoment.format(`YYYY-MM-DD[T]HH:mm:ss[Z]`),
        //@ts-ignore
        horaDaVisita: '',
        nomeFiscalizado: formParam.revenda.nome,
        revenda: form.getFieldValue('revenda'),
        assinatura: assinaturaBase64Image,
        statusAssinatura: form.getFieldValue('statusAssinatura'),
        servidores: listaServidores,
      };

      if (online) {
        if (params.local) {
          saveTFRLocal(formTFRDTO);
        } else {
          if (shouldSaveOffline) saveTFRLocal(formTFRDTO);
          else saveTFROnline(formTFRDTO);
        }
      } else {
        //@ts-ignore
        if (!formTFRDTO.id) saveTFRLocal(formTFRDTO);
        else saveTFRLocal(formTFRDTO);
      }
      return false;
    },
    [
      assinaturaBase64Image,
      form,
      setLoading,
      dataSourceTableChavesPrimarias,
      online,
      params.local,
      saveTFRLocal,
      saveTFROnline,
      shouldSaveOffline,
      listaServidores,
      temVeterinarioNaLista,
    ]
  );

  const handleSelectChavePrimariaChange = (value: any) => {
    let obj = JSON.parse(value);

    if (obj && obj.tiposChavesSecundariasTermoFiscalizacao) {
      setChavesSecundarias(obj.tiposChavesSecundariasTermoFiscalizacao);
      form.setFieldsValue({
        modalTipoChavePrimaria: { id: obj.id, nome: obj.nome },
      });
    }
  };

  const handleSelectChaveSecundariaChange = (list: any[]) => {
    let listaParseada: any[] = [];

    list.forEach((obj, i) => {
      listaParseada = listaParseada.concat(JSON.parse(obj));
    });

    form.setFieldsValue({
      modalListaTipoChaveSecundaria: listaParseada,
    });
  };

  const handleAddPrograma = useCallback(() => {
    const tipoChaveSendoInserida: ChavePrincipalTFR.Request['tipoChavePrincipalTermoFiscalizacao'] =
      form.getFieldValue('modalTipoChavePrimaria');

    const arrayDeChavesIguaisAChaveQueEstaSendoInserida =
      dataSourceTableChavesPrimarias?.filter((chave) => {
        return (
          chave.tipoChavePrincipalTermoFiscalizacao?.id ===
          tipoChaveSendoInserida?.id
        );
      });

    if (
      arrayDeChavesIguaisAChaveQueEstaSendoInserida &&
      arrayDeChavesIguaisAChaveQueEstaSendoInserida?.length > 0
    ) {
      notification.warn({
        message: 'Esta chave principal já foi incluída',
        description: 'Selecione outra chave principal',
      });
      return;
    }

    const listaTipoChaveSecundaria: TipoChaveSecundariaTFR.Summary[] =
      form.getFieldValue('modalListaTipoChaveSecundaria');

    let list = listaTipoChaveSecundaria?.map((s) => {
      return { tipoChaveSecundariaTermoFiscalizacao: s };
    });

    const novaChaveASerIncluida: typeof dataSourceTableChavesPrimarias = [
      {
        tipoChavePrincipalTermoFiscalizacao: {
          id: tipoChaveSendoInserida?.id,
          nome: tipoChaveSendoInserida?.nome,
        },
        chavesSecundariasTermoFiscalizacao: list,
      },
    ];

    setDatasourceTableChavesPrimarias(
      dataSourceTableChavesPrimarias?.concat(novaChaveASerIncluida)
    );
    setShowModalProgramas(false);
    cleanModalProgramasEAtividades();
    form.validateFields(['tableChavesPrimarias']);
    notification.success({
      message: 'Programa e atividades incluído com sucesso',
    });
  }, [dataSourceTableChavesPrimarias, form, cleanModalProgramasEAtividades]);

  useEffect(() => {
    if (props.tfr?.chavesPrincipaisTermoFiscalizacao) {
      setDatasourceTableChavesPrimarias(
        props.tfr?.chavesPrincipaisTermoFiscalizacao
      );
    }
  }, [mode, props.tfr?.chavesPrincipaisTermoFiscalizacao]);
  useEffect(() => {
    if (showModalProgramas) {
      dispatch(fetchActive());
    }
  }, [dispatch, showModalProgramas]);

  useEffect(() => {
    if (loading === 'pending') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loading, setLoading]);

  const chavePrimariaTableActions = (
    chavePrimaria: ChavePrincipalTFR.Request
  ) => {
    return (
      <Row align={'middle'} justify={xs ? 'start' : 'center'} gutter={12}>
        <Popconfirm
          title={'Deseja remover a chave primária?'}
          onConfirm={() => {
            setDatasourceTableChavesPrimarias(
              dataSourceTableChavesPrimarias?.filter(
                (chave) =>
                  chave.tipoChavePrincipalTermoFiscalizacao?.id !==
                  chavePrimaria.tipoChavePrincipalTermoFiscalizacao?.id
              )
            );
            notification.success({
              message: `Chave ${chavePrimaria.tipoChavePrincipalTermoFiscalizacao?.nome} removida`,
            });
          }}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            type={'ghost'}
            title={'Remover chave primária'}
          />
        </Popconfirm>
      </Row>
    );
  };

  return (
    <>
      <ModalFinalizacaoCadastro
        formData={dadosModalFinalizacao}
        formName='TFR'
        open={showModalFinalizacao}
        setOpen={setShowModalFinalizacao}
      />

      <fieldset disabled={formDisabled}>
        <Form<FormTFRType>
          layout={'vertical'}
          form={form}
          size={!xs ? 'small' : 'large'}
          onFinish={handleFormSubmit}
          onFinishFailed={() => {
            notification.error({
              message: 'Alguns campos obrigatórios não foram preenchidos',
              description: 'Por favor revise o formulário',
            });
          }}
          initialValues={props.tfr}
        >
          <Form.Item name={'id'} hidden>
            <Input disabled hidden />
          </Form.Item>

          <Divider />

          <Row gutter={24}>
            <Form.Item hidden>
              <Input disabled hidden />
            </Form.Item>
            <Col xs={15} lg={5}>
              <Form.Item
                label={'Data da Visita'}
                name={'dataVisita'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
                initialValue={moment.tz(
                  moment().toLocaleString(),
                  'America/Cuiaba'
                )}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(date) => {
                    return date.isAfter(moment().toDate());
                  }}
                  disabled={formDisabled}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
            <Col xs={9} lg={3}>
              <Form.Item
                label={'Hora'}
                name={'horaDaVisita'}
                rules={[{ required: true, message: 'O campo é obrigatório' }]}
                initialValue={
                  String(
                    moment
                      .tz(moment().toLocaleString(), 'America/Cuiaba')
                      .get('hour')
                  ).padStart(2, '0') +
                  ':' +
                  String(
                    moment
                      .tz(moment().toLocaleString(), 'America/Cuiaba')
                      .get('minute')
                  ).padStart(2, '0')
                }
              >
                <Input
                  inputMode='numeric'
                  autoComplete='off'
                  onClick={() => {
                    setShowModalTimePicker(true);
                  }}
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Divider orientation='left'>
                Descrição das atividades realizadas e principais observações
              </Divider>

              <Row>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={'Ocorrências'}
                    name={'ocorrencias'}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira as ocorrências!',
                      },
                    ]}
                  >
                    <TextArea showCount rows={5} maxLength={2000} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} lg={24}>
                  <RevendaPanel
                    form={form}
                    formDisabled={!params.local && mode !== 'CREATE'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider orientation='left'>Programas sanitários</Divider>
          <Row gutter={24}>
            <Col xs={24} sm={24}>
              <br />
              <Form.Item
                validateTrigger={'onSubmit'}
                rules={[
                  {
                    validator(_, value) {
                      if (
                        dataSourceTableChavesPrimarias &&
                        dataSourceTableChavesPrimarias.length < 1
                      )
                        return Promise.reject(
                          new Error('Deve ser informado pelo menos um programa')
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {/*TABELA CHAVES PRIMÁRIAS*/}
                <Table<ChavePrincipalTFR.Request>
                  size={'small'}
                  dataSource={dataSourceTableChavesPrimarias}
                  rowKey={(record) =>
                    record.tipoChavePrincipalTermoFiscalizacao?.nome as any
                  }
                  title={() => {
                    return (
                      <Row justify={'space-between'}>
                        <Typography.Title level={5} style={{ color: 'white' }}>
                          Atividades
                        </Typography.Title>
                        <Button
                          icon={<PlusOutlined />}
                          type={'primary'}
                          onClick={() => {
                            setShowModalProgramas(true);

                            scrollFieldToTop('tableChavesPrimarias');
                          }}
                          disabled={formDisabled}
                        />
                      </Row>
                    );
                  }}
                  columns={[
                    {
                      dataIndex: ['tipoChavePrincipalTermoFiscalizacao', 'id'],
                      responsive: ['xs'],
                      render(_, chavePrimaria) {
                        return (
                          <Descriptions size={'small'} column={1} bordered>
                            <Descriptions.Item label={'Chave principal'}>
                              {
                                chavePrimaria
                                  .tipoChavePrincipalTermoFiscalizacao?.nome
                              }
                            </Descriptions.Item>
                            <Descriptions.Item label={'Chaves secundárias'}>
                              {chavePrimaria.chavesSecundariasTermoFiscalizacao?.map(
                                (chave) => (
                                  <Tooltip
                                    title={
                                      chave.tipoChaveSecundariaTermoFiscalizacao
                                        ?.nome
                                    }
                                  >
                                    <Tag
                                      style={{
                                        maxWidth: '160px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                      key={
                                        chave
                                          .tipoChaveSecundariaTermoFiscalizacao
                                          ?.id
                                      }
                                    >
                                      {
                                        chave
                                          .tipoChaveSecundariaTermoFiscalizacao
                                          ?.nome
                                      }
                                    </Tag>
                                  </Tooltip>
                                )
                              )}
                            </Descriptions.Item>

                            <Descriptions.Item label='Ações'>
                              {chavePrimariaTableActions(chavePrimaria)}
                            </Descriptions.Item>
                          </Descriptions>
                        );
                      },
                    },
                    {
                      dataIndex: [
                        'tipoChavePrincipalTermoFiscalizacao',
                        'nome',
                      ],
                      title: 'Chave primária',
                      responsive: ['sm'],
                    },
                    {
                      dataIndex: 'chavesSecundariasTermoFiscalizacao',
                      title: 'Chaves secundárias',
                      responsive: ['sm'],
                      width: 600,
                      render(chaves: ChaveSecundariaTFR.Summary[]) {
                        return chaves?.map((chave) => (
                          <Tag
                            key={chave.tipoChaveSecundariaTermoFiscalizacao?.id}
                          >
                            <Typography.Text
                              ellipsis
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '200px',
                              }}
                            >
                              {chave.tipoChaveSecundariaTermoFiscalizacao?.nome}
                            </Typography.Text>
                          </Tag>
                        ));
                      },
                    },
                    {
                      dataIndex: 'id',
                      title: 'Ações',
                      responsive: ['sm'],
                      width: 60,
                      render(id, chavePrimaria) {
                        return chavePrimariaTableActions(chavePrimaria);
                      },
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left'>Adicionar Servidores</Divider>
          <TFRServidoresPanel
            form={form}
            formDisabled={formDisabled}
            listaServidores={listaServidores}
            setListaServidores={setListaServidores}
          />
          <Divider orientation='left'>Assinatura do responsável</Divider>

          <Col xs={24} lg={24}>
            <Form.Item
              label={'Como foi realizada a assinatura?'}
              name={'statusAssinatura'}
              valuePropName={'checked'}
              rules={[{ required: true, message: 'O campo é obrigatório' }]}
              initialValue={form.getFieldValue('statusAssinatura')}
            >
              <Radio.Group
                value={
                  statusAssinaturaState ||
                  form.getFieldValue('statusAssinatura')
                }
                onChange={(e) => {
                  setStatusAssinaturaState(e.target.value);
                  form.setFieldsValue({ statusAssinatura: e.target.value });
                }}
              >
                <Space direction='vertical'>
                  {listaStatusAssinatura?.map((statusAssinatura) => {
                    let label;

                    // Mapeia o statusAssinatura para o label correspondente
                    switch (statusAssinatura) {
                      case 'ASSINADO_DIGITALMENTE':
                        label = 'Assinado Digitalmente';
                        break;
                      case 'ASSINADO_FISICAMENTE':
                        label = 'Assinado Fisicamente';
                        break;
                      case 'AUSENTE_ESTABELECIMENTO':
                        label = 'Ausência de Pessoas no Estabelecimento';
                        break;
                      case 'PRODUTOR_RECUSOU_ASSINAR':
                        label = 'Responsável recusou-se a assinar';
                        break;
                      default:
                        label = '';
                    }
                    if (label) {
                      return (
                        <Radio key={statusAssinatura} value={statusAssinatura}>
                          {label}
                        </Radio>
                      );
                    }
                    return null;
                  })}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>

          {statusAssinaturaState ===
            StatusAssinatura.keyOf(StatusAssinatura.ASSINADO_DIGITALMENTE) && (
            <Col span={24}>
              <Form.Item
                validateTrigger={'onSubmit'}
                name='assinatura'
                rules={[
                  {
                    validator(_, value) {
                      if (
                        statusAssinaturaState ===
                        StatusAssinatura.keyOf(
                          StatusAssinatura.ASSINADO_DIGITALMENTE
                        )
                      ) {
                        if (!assinaturaBase64Image)
                          return Promise.reject(
                            new Error(
                              'Deve ser informada a assinatura do produtor'
                            )
                          );
                        return Promise.resolve();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <SignatureWrapper
                  default={props.tfr?.assinatura}
                  disabled={formDisabled}
                  onSaveSignature={() => {
                    form.validateFields(['assinatura']);
                  }}
                  onClearSignature={() => {
                    // form.setFieldsValue(() => []);
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <br />
          <br />
          <Row justify={'end'} gutter={24} style={{ marginTop: 50 }}>
            {!params.local && online && (
              <Col xs={24}>
                <Row justify={'end'}>
                  <Col>
                    <Form.Item>
                      <Switch
                        checked={shouldSaveOffline}
                        onChange={setShouldSaveOffline}
                      />
                      <span> Salvar offline</span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={12} lg={6}>
              <Button
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(`${online ? '/tfr' : '/acoes-de-campo'}`)
                }
              >
                {formDisabled ? 'Voltar' : 'Cancelar'}
              </Button>
            </Col>
            {!formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                >
                  Salvar
                </Button>
              </Col>
            )}
            {formDisabled && (
              <Col xs={12} lg={6}>
                <Button
                  style={{ width: '100%' }}
                  htmlType={'submit'}
                  type={'primary'}
                  onClick={() => {
                    navigate(`/tfr/edicao/${params.id}`);
                  }}
                >
                  Editar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </fieldset>

      {/* Problemas sanitários e atividades */}
      <CustomModal
        centered
        open={showModalProgramas}
        title={'Cadastro de programas e atividades'}
        onCancel={() => {
          setShowModalProgramas(false);
          cleanModalProgramasEAtividades();
        }}
        width={750}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ width: '50%' }}
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                setShowModalProgramas(false);
                cleanModalProgramasEAtividades();
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{ width: '50%' }}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                form.validateFields([['modalTipoChavePrimaria', 'id']]);
                form.validateFields(['modalListaTipoChaveSecundaria']);

                const tipoChavePrimaria = form.getFieldValue(
                  'modalTipoChavePrimaria'
                );

                const listaTipoChaveSecundaria = form.getFieldValue(
                  'modalListaTipoChaveSecundaria'
                );

                if (!tipoChavePrimaria) return;
                if (chavesSecundarias.length > 0 && !listaTipoChaveSecundaria)
                  return;
                handleAddPrograma();
              }}
            >
              Salvar
            </Button>
          </div>
        }
        maskClosable={false}
        destroyOnClose
      >
        <Form layout={'vertical'} form={form} autoComplete={'off'}>
          <Row gutter={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Chave Principal'}
                name={['modalTipoChavePrimaria', 'id']}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
                valuePropName={'modaltipochaveprimaria'}
              >
                <Select
                  placeholder={'Selecione uma chave'}
                  onChange={handleSelectChavePrimariaChange}
                  placement={'bottomLeft'}
                >
                  {tfrs?.map((tipo) => {
                    return (
                      <Select.Option
                        key={tipo.nome}
                        value={JSON.stringify(tipo)}
                      >
                        {tipo?.nome}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={0} lg={0}>
              <Form.Item name={['modalTipoChavePrimaria', 'nome']} hidden>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label={'Chave Secundária'}
                name={'modalListaTipoChaveSecundaria'}
                valuePropName={'modalListaTipoChaveSecundaria'}
                rules={[
                  {
                    required: chavesSecundarias && chavesSecundarias.length > 0,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          icon={<CloseOutlined />}
                          onClick={() => setShowDropdownChaveSecundaria(false)}
                          style={{ marginRight: '5px' }}
                          type='primary'
                        >
                          Fechar
                        </Button>
                      </div>
                    </>
                  )}
                  mode='multiple'
                  placeholder={'Selecione as chaves secundárias'}
                  onChange={handleSelectChaveSecundariaChange}
                  onDropdownVisibleChange={(visible) =>
                    setShowDropdownChaveSecundaria(visible)
                  }
                  open={showDropdownChaveSecundaria}
                  showSearch={false}
                  placement={'bottomLeft'}
                >
                  {chavesSecundarias?.map((tipo) => (
                    <Select.Option key={tipo.id} value={JSON.stringify(tipo)}>
                      {tipo.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        title={null}
        footer={null}
        open={showModalTimePicker}
        bodyStyle={{ display: 'inline-block', float: 'left' }}
      >
        <div style={{ display: 'inline-block', float: 'left' }}>
          <StaticTimePicker
            defaultValue={moment.tz(
              moment().toLocaleString(),
              'America/Cuiaba'
            )}
            timezone='America/Cuiaba'
            localeText={{
              cancelButtonLabel: 'Cancelar',
              toolbarTitle: '',
            }}
            onAccept={(data: Moment | null) => {
              let hour =
                String(data?.get('hour')).padStart(2, '0') +
                ':' +
                String(data?.get('minute')).padStart(2, '0');
              form.setFieldValue('horaDaVisita', hour);

              setShowModalTimePicker(false);
            }}
            slots={{
              actionBar: undefined,
            }}
            onClose={() => {
              setShowModalTimePicker(false);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
}
